import PushNotificationApi from 'api/push_notification/PushNotificationApi'
import {
  PushNotificationRequest,
  PushNotificationRequestSchema,
} from 'api/push_notification/PushNotificationPayloads'
import CreatorUserApi from 'api/users/creator/CreatorUserApi'
import { CreatorUserResponse } from 'api/users/creator/CreatorUserPayloads'
import { AxiosError } from 'axios'
import FormElement from 'components/form/element/FormElement'
import useYelaForm from 'hooks/useYelaForm'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'

const Container = tw.div`
  px-10 max-w-6xl
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

const ButtonContainer = tw.div`
    float-right    
`

const PushNotification = (): JSX.Element => {
  const form = useYelaForm<PushNotificationRequest>(
    PushNotificationRequestSchema,
    {
      userId: undefined,
      title: undefined,
      subtitle: undefined,
      content: undefined,
    }
  )
  const toast = useRef<Toast>(null)
  const [item, setItem] = useState<PushNotificationRequest>()
  const [creatorUsers, setCreatorUsers] = useState<CreatorUserResponse[]>()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const response = await CreatorUserApi.findAll(
        {
          page: 0,
          pageSize: 1000,
        },
        {
          desc: false,
          sortBy: 'name',
        },
        {}
      )
      if (response) {
        setCreatorUsers(response.items)
      }
    })()
  }, [])
  async function onSend() {
    await form?.trigger()
    if (!form?.formState.isValid) return

    PushNotificationApi.send(form.getValues())
      .then(async () => {
        form.reset({
          userId: undefined,
          title: { en: '', ar: '' },
          subtitle: { en: '', ar: '' },
          content: { en: '', ar: '' },
        })
        setItem({})
        toast.current?.show({
          severity: 'success',
          summary: `Success`,
          detail: 'Push notification has been sent successfully',
          sticky: false,
        })
      })
      .catch((error: AxiosError) => {
        toast.current?.show({
          severity: 'error',
          summary: `Status: ${error.response?.status} ${
            error.response?.data.errors
              ? error.response.data.errors.join(' ')
              : ''
          }`,
          detail: error.response?.data?.message,
          sticky: true,
        })
      })
  }
  return (
    <Container>
      <Toast ref={toast} />
      <Flex>
        <Flex1>
          <FormElement
            id="userId"
            labelText="Creator User"
            errorText={form.formState.errors.userId?.message}
          >
            <Dropdown
              id="userId"
              {...form.register('userId')}
              options={creatorUsers}
              value={item?.userId}
              optionLabel="name"
              optionValue="id"
              onChange={(e) => {
                setItem({ ...item, userId: e.value })
                form.setValue('userId', e.value)
              }}
            />
          </FormElement>
        </Flex1>
      </Flex>
      <Flex>
        <Flex1>
          <FormElement
            id="title-en"
            labelText="Title - EN"
            errorText={form.formState.errors.title?.en?.message}
          >
            <input
              id="title-en"
              dir="auto"
              className="p-inputtext p-component p-filled"
              {...form.register('title.en')}
              value={item?.title?.en}
            />
          </FormElement>
        </Flex1>
        <Flex1>
          <FormElement
            id="title-ar"
            labelText="Title - AR"
            errorText={form.formState.errors.title?.ar?.message}
          >
            <input
              id="title-ar"
              dir="auto"
              className="p-inputtext p-component p-filled"
              {...form.register('title.ar')}
              value={item?.title?.ar}
            />
          </FormElement>
        </Flex1>
      </Flex>

      <Flex>
        <Flex1>
          <FormElement
            id="subtitle-en"
            labelText="Subtitle - EN"
            errorText={form.formState.errors.subtitle?.en?.message}
          >
            <input
              id="subtitle-en"
              dir="auto"
              className="p-inputtext p-component p-filled"
              {...form.register('subtitle.en')}
              value={item?.subtitle?.en}
            />
          </FormElement>
        </Flex1>
        <Flex1>
          <FormElement
            id="subtitle-ar"
            labelText="Subtitle - AR"
            errorText={form.formState.errors.subtitle?.ar?.message}
          >
            <input
              id="subtitle-ar"
              dir="auto"
              className="p-inputtext p-component p-filled"
              {...form.register('subtitle.ar')}
              value={item?.subtitle?.ar}
            />
          </FormElement>
        </Flex1>
      </Flex>

      <Flex>
        <Flex1>
          <FormElement
            id="content-en"
            labelText="Content - EN"
            errorText={form.formState.errors.content?.en?.message}
          >
            <textarea
              id="content-en"
              dir="auto"
              rows={5}
              className="p-inputtextarea p-inputtext p-component"
              {...form.register('content.en')}
              value={item?.content?.en}
            />
          </FormElement>
        </Flex1>
        <Flex1>
          <FormElement
            id="content-ar"
            labelText="Content - AR"
            errorText={form.formState.errors.content?.ar?.message}
          >
            <textarea
              id="content-ar"
              dir="auto"
              rows={5}
              className="p-inputtextarea p-inputtext p-component"
              {...form.register('content.ar')}
              value={item?.content?.ar}
            />
          </FormElement>
        </Flex1>
      </Flex>
      <ButtonContainer>
        <Button
          className="p-button-primary"
          icon="pi pi-play"
          label={'Send'}
          onClick={onSend}
        />
      </ButtonContainer>
    </Container>
  )
}

export default PushNotification
