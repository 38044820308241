import { atom, DefaultValue } from 'recoil'

const AUTH_STATE_STORAGE_KEY = 'auth-state'

export type AuthState = {
  id: string
  name: string
  permissions: string[]
  accessToken: string
  refreshToken: string
}

export class AuthStateManager {
  static clearState(): void {
    localStorage.removeItem(AUTH_STATE_STORAGE_KEY)
  }

  static storeState(state: AuthState): void {
    localStorage.setItem(AUTH_STATE_STORAGE_KEY, JSON.stringify(state))
  }

  static readState(): AuthState | null {
    const stored = localStorage.getItem(AUTH_STATE_STORAGE_KEY)

    if (!stored) return null

    try {
      return JSON.parse(stored)
    } catch (e) {
      AuthStateManager.clearState()
      return null
    }
  }
}

export const AuthState = atom<AuthState | null>({
  key: 'AuthState',
  default: AuthStateManager.readState(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((state) => {
        if (state == null || state instanceof DefaultValue) {
          AuthStateManager.clearState()
        } else {
          AuthStateManager.storeState(state)
        }
      })
    },
  ],
})
