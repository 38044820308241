import { Dropdown, DropdownChangeParams } from 'primereact/dropdown'
import React, { useState } from 'react'
import { TOrderStatusFilter, TOptions } from './OrderStatusFilter.types'

const OrderStatusFilterTemplate = (
  props: TOrderStatusFilter
): React.ReactNode => {
  const [status, setStatus] = useState(null)
  const onStatusChange = (e: DropdownChangeParams) => {
    props.dataTable.current?.filter(e.value, 'status', 'equals')
    setStatus(e.value)
  }
  return (
    <Dropdown
      value={status}
      options={[
        { name: 'ALL', value: '' } as TOptions,
        { name: 'PENDING', value: 'PENDING' } as TOptions,
        {
          name: 'WAITING REQUEST REVIEW',
          value: 'WAITING_REQUEST_REVIEW',
        } as TOptions,
        {
          name: 'WAITING CREATOR RESPONSE',
          value: 'WAITING_CREATOR_RESPONSE',
        } as TOptions,
        {
          name: 'WAITING RESPONSE REVIEW',
          value: 'WAITING_RESPONSE_REVIEW',
        } as TOptions,
        { name: 'RESPONSE APPROVED', value: 'RESPONSE_APPROVED' } as TOptions,
        {
          name: 'FILES PREPARED TO SEND',
          value: 'FILES_PREPARED_TO_SEND',
        } as TOptions,
        { name: 'COMPLETED', value: 'COMPLETED' } as TOptions,
        { name: 'REFUNDED', value: 'REFUNDED' } as TOptions,
      ]}
      optionLabel="name"
      optionValue="value"
      onChange={onStatusChange}
      className="p-column-filter"
    />
  )
}

export { OrderStatusFilterTemplate }
