import FanUserApi from 'api/users/fan/FanUserApi'
import {
  FanUserRequest,
  FanUserResponse,
  Language,
} from 'api/users/fan/FanUserPayloads'
import FormElement from 'components/form/element/FormElement'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import tw from 'twin.macro'

const Container = tw.div`
  px-4
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

type TFanUserDetail = {
  id?: string
  form: UseFormReturn<FanUserRequest>
}

const FanUserDetail = ({
  id,
  form: {
    register,
    setValue,
    formState: { errors },
  },
}: TFanUserDetail): JSX.Element => {
  const [item, setItem] = useState<FanUserResponse>()

  useEffect(() => {
    setValue('enabled', false)
    setValue('applyPromotion', false)
    if (!id) return
    ;(async () => {
      const response = await FanUserApi.get(id).catch((error) => {
        console.log(error) //TODO
      })
      if (response) {
        setItem(response)
        setValue('email', response.email as string)
        setValue('name', response.name as string)
        setValue('enabled', response.enabled as boolean)
        setValue('applyPromotion', response.applyPromotion as boolean)
        setValue('language', response.language as Language)
      }
    })()
  }, [])

  return (
    <Container>
      {item && item.id && (
        <>
          <Flex>
            <Flex1>
              <FormElement id="id" labelText="ID">
                <InputText id="id" disabled defaultValue={item.id} />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="created-at" labelText="Created At">
                <Calendar
                  id="created-at"
                  disabled
                  showIcon
                  showTime
                  value={new Date(item.createdAt as Date)}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="updated-at" labelText="Updated At">
                <Calendar
                  id="updated-at"
                  disabled
                  showIcon
                  showTime
                  value={item.updatedAt && new Date(item.updatedAt)}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement id="registered" labelText="Registered">
                <Checkbox checked={item?.registered} disabled></Checkbox>
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="verified" labelText="Verified">
                <Checkbox checked={item?.verified} disabled></Checkbox>
              </FormElement>
            </Flex1>
          </Flex>
        </>
      )}

      <div>
        <Flex>
          <Flex1>
            <FormElement id="enabled" labelText="Enabled">
              <Checkbox
                onChange={(e) => {
                  setItem({ ...item, enabled: e.checked })
                  setValue('enabled', e.checked)
                }}
                checked={item?.enabled}
              ></Checkbox>
            </FormElement>
          </Flex1>
          <Flex1>
            <FormElement id="applyPromotion" labelText="Apply Promotion">
              <Checkbox
                onChange={(e) => {
                  setItem({ ...item, applyPromotion: e.checked })
                  setValue('applyPromotion', e.checked)
                }}
                checked={item?.applyPromotion}
              ></Checkbox>
            </FormElement>
          </Flex1>
        </Flex>

        {item && item.id && (
          <FormElement id="name" labelText="Name">
            <InputText id="name" dir="auto" {...register('name')} />
          </FormElement>
        )}

        <FormElement
          id="email"
          labelText="Email"
          errorText={errors.email?.message}
        >
          <InputText id="email" dir="auto" {...register('email')} />
        </FormElement>
        {!item?.id && (
          <FormElement id="language" labelText="Language">
            <Dropdown
              id="language"
              {...register('language')}
              value={item?.language}
              options={[
                {
                  code: 'en',
                },
                {
                  code: 'ar',
                },
              ]}
              optionLabel="code"
              optionValue="code"
              onChange={(e) => {
                setItem({ ...item, language: e.value })
                setValue('language', e.value)
              }}
            />
          </FormElement>
        )}
      </div>
    </Container>
  )
}

export default FanUserDetail
