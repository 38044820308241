import { client } from 'api/api.root'
import { AxiosResponse } from 'axios'
import { PushNotificationRequest } from './PushNotificationPayloads'

export default class PushNotificationApi {
  static send = async (
    payload: PushNotificationRequest
  ): Promise<AxiosResponse> => {
    const response = await client.post<AxiosResponse>(
      '/notifications/push/creators',
      payload
    )

    return response.data
  }
}
