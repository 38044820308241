/* eslint-disable @typescript-eslint/no-extra-semi */
import PromotionApi from 'api/promotions/PromotionApi'
import {
  PromotionRequest,
  PromotionRequestSchema,
  PromotionResponse,
} from 'api/promotions/PromotionPayloads'
import { AxiosError } from 'axios'
import useYelaForm from 'hooks/useYelaForm'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import 'twin.macro'
import ListView from 'views/common/list_view/ListView'
import { PromotionTypeFilterTemplate } from './filters/PromotionTypeFilter'
import PromotionDetail from './PromotionDetail'

const PromotionsList = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const dataTable = useRef<DataTable>(null)
  const [, setEditItem] = useState<PromotionResponse>()
  const [, setShowDialog] = useState<boolean>(false)
  const form = useYelaForm<PromotionRequest>(PromotionRequestSchema)
  const [type, setType] = useState(null)

  return (
    <>
      <Toast ref={toast} />
      <ListView<PromotionResponse, PromotionRequest>
        dataTable={dataTable}
        viewName="Promotions"
        dataKey="id"
        columns={[
          {
            display: 'Email',
            field: 'email',
            sortKey: 'email',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Code',
            field: 'code',
            sortKey: 'code',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Type',
            field: 'type',
            sortKey: 'type',
            sortable: true,
            filterable: true,
            filterBody: PromotionTypeFilterTemplate({
              dataTable: dataTable,
              setStateFunc: setType,
              value: type,
            }),
          },
          {
            display: 'Amount',
            field: 'amount',
            sortKey: 'amount',
            sortable: true,
            filterable: false,
          },
          {
            display: 'Valid Until',
            field: 'validUntil',
            sortKey: 'validUntil',
            sortable: true,
            filterable: false,
          },
          {
            display: 'Valid After',
            field: 'validAfter',
            sortKey: 'validAfter',
            sortable: true,
            filterable: false,
          },
        ]}
        defaultSort={{ sortBy: 'id', desc: false }}
        onFetch={PromotionApi.findAll}
        form={form}
        dialogContent={(id?: string) => <PromotionDetail id={id} form={form} />}
        onNew={async () => {
          setEditItem(undefined)
          setShowDialog(true)
        }}
        onDetails={async (item: PromotionResponse) => {
          setEditItem(item)
          setShowDialog(true)
        }}
        onUpdate={async (id: string) => {
          await PromotionApi.update(id, form.getValues())
        }}
        onCreate={async () => {
          await PromotionApi.create(form.getValues())
        }}
        onDelete={async (items: PromotionResponse[]) => {
          await PromotionApi.deleteAll(items.map((i) => i.id as string)).catch(
            (error: AxiosError) => {
              toast.current?.show({
                severity: 'error',
                summary: `Status: ${error.response?.status}`,
                detail: error.response?.data?.message,
                life: 3000,
              })
            }
          )
        }}
      />
    </>
  )
}

export default PromotionsList
