import { client } from 'api/api.root'
import { Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import {
  CreatorDetailFilter,
  CreatorDetailRequest,
  CreatorDetailResponse,
} from './CreatorPayloads'

export default class CreatorApi {
  static endpoint = 'creators'

  static create = async (
    payload: CreatorDetailRequest
  ): Promise<CreatorDetailResponse> => {
    const response = await client.post<CreatorDetailResponse>(
      `/${CreatorApi.endpoint}`,
      payload
    )

    return response.data
  }

  static update = async (
    id: string,
    payload: CreatorDetailRequest
  ): Promise<CreatorDetailResponse> => {
    const response = await client.put<CreatorDetailResponse>(
      `/${CreatorApi.endpoint}/${id}`,
      payload
    )

    return response.data
  }

  static delete = async (id: string): Promise<void> => {
    await client.delete(`/${CreatorApi.endpoint}/${id}`)
  }

  static deleteAll = async (ids: string[]): Promise<void> => {
    await Promise.all(
      ids.map((id) => client.delete(`/${CreatorApi.endpoint}/${id}`))
    )
  }

  static get = async (id: string): Promise<CreatorDetailResponse> => {
    const response = await client.get<CreatorDetailResponse>(
      `/${CreatorApi.endpoint}/${id}`
    )

    return response.data
  }

  static findAll = async (
    pageable: Pageable,
    sortable: Sortable,
    filter: CreatorDetailFilter
  ): Promise<PagedResponse<CreatorDetailResponse>> => {
    const response = await client.get<PagedResponse<CreatorDetailResponse>>(
      `/${CreatorApi.endpoint}`,
      { params: { ...pageable, ...sortable, ...filter } }
    )

    return response.data
  }

  static findAllNotAssigned = async (
    includeId?: string
  ): Promise<PagedResponse<CreatorDetailResponse>> => {
    const response = await client.get<PagedResponse<CreatorDetailResponse>>(
      `/creators-not-assigned`,
      { params: { id: includeId } }
    )

    return response.data
  }
}
