import 'twin.macro'
import AuthApi from 'api/auth/AuthApi'
import { LoginRequest, LoginRequestSchema } from 'api/auth/LoginPayloads'
import { AxiosError } from 'axios'
import useYelaForm from 'hooks/useYelaForm'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthStateManager } from 'states/AuthState'
import { LoginForm, LoginWrapper } from './Login.styles'

const Login = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const history = useHistory()

  useEffect(() => {
    if (AuthStateManager.readState()) {
      history.push('/quick-create')
    }
  })

  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useYelaForm<LoginRequest>(LoginRequestSchema)

  const onLogin = async (payload: LoginRequest) => {
    await trigger()
    if (errors === undefined || errors == {}) return

    const response = await AuthApi.login(payload).catch((error: AxiosError) => {
      toast.current?.show({
        severity: 'error',
        summary: `Status: ${error.response?.status}`,
        detail: error.response?.data?.message,
        life: 3000,
      })
    })

    if (response) {
      AuthStateManager.storeState(response)
      history.push('/quick-create')
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <LoginWrapper>
        <LoginForm
          className="p-shadow-5"
          onSubmit={handleSubmit(onLogin)}
          noValidate={true}
        >
          <div tw="flex flex-row items-center">
            <img src="/logo.svg" tw="h-20 w-20" />
            <span tw="flex-1 text-3xl text-center">Yela Dashboard</span>
          </div>

          <div>
            <span className="p-float-label">
              <InputText
                id="email"
                type="email"
                {...register('email')}
                className={errors['email'] ? 'p-invalid' : ''}
              />
              <label htmlFor="email">Email</label>
            </span>

            <small
              className="p-error"
              css={{ visibility: errors['email'] ? 'visible' : 'hidden' }}
            >
              {errors['email']?.message || '--'}
            </small>
          </div>

          <div>
            <span className="p-float-label">
              <InputText
                id="password"
                type="password"
                {...register('password')}
                className={errors['password'] ? 'p-invalid' : ''}
              />
              <label htmlFor="password">Password</label>
            </span>
            <small
              className="p-error"
              css={{ visibility: errors['password'] ? 'visible' : 'hidden' }}
            >
              {errors['password']?.message || '--'}
            </small>
          </div>

          <Button label="Login" />
        </LoginForm>
      </LoginWrapper>
    </>
  )
}

export default Login
