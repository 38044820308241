import React from 'react'
import { Route } from 'react-router-dom'

export type AnonymousProps = {
  path: string
  component: () => JSX.Element
}

export function Anonymous({ path, component }: AnonymousProps): JSX.Element {
  return <Route path={path}>{component()}</Route>
}
