import { client } from 'api/api.root'
import { Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import {
  OccasionFilter,
  OccasionRequest,
  OccasionResponse,
} from './OccasionPayloads'

export default class OccasionApi {
  static create = async (
    payload: OccasionRequest
  ): Promise<OccasionResponse> => {
    const response = await client.post<OccasionResponse>('/occasions', payload)

    return response.data
  }

  static update = async (
    id: string,
    payload: OccasionRequest
  ): Promise<OccasionResponse> => {
    const response = await client.put<OccasionResponse>(
      `/occasions/${id}`,
      payload
    )

    return response.data
  }

  static delete = async (id: string): Promise<void> => {
    await client.delete(`/occasions/${id}`)
  }

  static deleteAll = async (ids: string[]): Promise<void> => {
    await Promise.all(ids.map((id) => client.delete(`/occasions/${id}`)))
  }

  static get = async (id: string): Promise<OccasionResponse> => {
    const response = await client.get<OccasionResponse>(`/occasions/${id}`)

    return response.data
  }

  static findAll = async (
    pageable: Pageable,
    sortable: Sortable,
    filter: OccasionFilter
  ): Promise<PagedResponse<OccasionResponse>> => {
    const response = await client.get<PagedResponse<OccasionResponse>>(
      '/occasions',
      { params: { ...pageable, ...sortable, ...filter } }
    )

    return response.data
  }
}
