import tw, { css, styled } from 'twin.macro'

export const NavigationWrapper = styled.div<{ collapsed: boolean }>`
  ${tw`h-screen`}
  background-color: var(--surface-a);
  ${(props) =>
    props.collapsed
      ? css`
          width: 50px;
          position: fixed;
        `
      : css`
          width: 350px;
          position: fixed;
          z-index: 10;
        `}
`

export const NavigationHeader = styled.div`
  height: 90px;
  font-size: 20px;
  ${tw`flex flex-row justify-center items-center p-6`}
`

export const NavigationList = styled.div`
  height: 100%;
  ${tw`h-full mt-4 p-2 space-y-2`}
`

export const NavigatorItem = styled.div<{ selected: boolean }>`
  ${tw`border-transparent border-2`};
  ${(props) => (props.selected ? tw`border-yellow-500 border-2` : '')};
  ${tw`hover:border-gray-500 hover:border-2`};
  ${tw`flex flex-row items-center space-x-3 p-3`};
  ${tw`text-center text-xl shadow-md cursor-pointer`};
`
