import { client } from 'api/api.root'
import { Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import {
  CurrencyFilter,
  CurrencyRequest,
  CurrencyResponse,
} from './CurrencyPayloads'

export default class CurrencyApi {
  static endpoint = 'currencies'

  static create = async (
    payload: CurrencyRequest
  ): Promise<CurrencyResponse> => {
    const response = await client.post<CurrencyResponse>(
      `/${CurrencyApi.endpoint}`,
      payload
    )

    return response.data
  }

  static update = async (
    id: string,
    payload: CurrencyRequest
  ): Promise<CurrencyResponse> => {
    const response = await client.put<CurrencyResponse>(
      `/${CurrencyApi.endpoint}/${id}`,
      payload
    )

    return response.data
  }

  static delete = async (id: string): Promise<void> => {
    await client.delete(`/${CurrencyApi.endpoint}/${id}`)
  }

  static deleteAll = async (ids: string[]): Promise<void> => {
    await Promise.all(
      ids.map((id) => client.delete(`/${CurrencyApi.endpoint}/${id}`))
    )
  }

  static get = async (id: string): Promise<CurrencyResponse> => {
    const response = await client.get<CurrencyResponse>(
      `/${CurrencyApi.endpoint}/${id}`
    )

    return response.data
  }

  static findAll = async (
    pageable: Pageable,
    sortable: Sortable,
    filter: CurrencyFilter
  ): Promise<PagedResponse<CurrencyResponse>> => {
    const response = await client.get<PagedResponse<CurrencyResponse>>(
      `/${CurrencyApi.endpoint}`,
      { params: { ...pageable, ...sortable, ...filter } }
    )

    return response.data
  }
}
