import { Dropdown, DropdownChangeParams } from 'primereact/dropdown'
import React from 'react'
import { TPromotionTypeFilter, TOptions } from './PromotionTypeFilter.types'

const PromotionTypeFilterTemplate = (
  props: TPromotionTypeFilter
): React.ReactNode => {
  const onTypeChange = (e: DropdownChangeParams) => {
    props.dataTable.current?.filter(e.value, 'type', 'equals')
    props.setStateFunc(e.value)
  }
  return (
    <Dropdown
      value={props.value}
      options={[
        { name: 'ALL', value: '' } as TOptions,
        { name: 'fixed-amount', value: 'FIXED_AMOUNT' } as TOptions,
        { name: 'percentage', value: 'PERCENTAGE' } as TOptions,
      ]}
      optionLabel="name"
      optionValue="value"
      onChange={onTypeChange}
      className="p-column-filter"
    />
  )
}

export { PromotionTypeFilterTemplate }
