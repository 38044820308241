import { Dropdown, DropdownChangeParams } from 'primereact/dropdown'
import React, { useState } from 'react'
import { TBooleanFilter, TOptions } from './BooleanFilter.types'

const BooleanFilterTemplate = (props: TBooleanFilter): React.ReactNode => {
  const [value, setValue] = useState(null)
  const onEnabledChange = (e: DropdownChangeParams) => {
    props.dataTable.current?.filter(e.value, props.field, 'equals')
    setValue(e.value)
  }
  return (
    <Dropdown
      value={value}
      options={[
        { name: 'ALL', value: '' } as TOptions,
        { name: 'TRUE', value: 'true' } as TOptions,
        { name: 'FALSE', value: 'false' } as TOptions,
      ]}
      optionLabel="name"
      optionValue="value"
      onChange={onEnabledChange}
      className="p-column-filter"
    />
  )
}

export { BooleanFilterTemplate }
