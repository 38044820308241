import CurrencyApi from 'api/currencies/CurrencyApi'
import {
  CurrencyRequest,
  CurrencyResponse,
} from 'api/currencies/CurrencyPayloads'
import FormElement from 'components/form/element/FormElement'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { MultiSelect } from 'primereact/multiselect'
import { Checkbox } from 'primereact/checkbox'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { CurrencyCode, Locale } from 'api/currencies/CurrencyCore'
import tw from 'twin.macro'

const Container = tw.div`
  px-4
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

type TCurrencyDetail = {
  id?: string
  form: UseFormReturn<CurrencyRequest>
}

const CurrencyDetail = ({
  id,
  form: {
    register,
    setValue,
    formState: { errors },
  },
}: TCurrencyDetail): JSX.Element => {
  const [item, setItem] = useState<CurrencyResponse>()

  useEffect(() => {
    if (!id) return
    ;(async () => {
      const response = await CurrencyApi.get(id).catch((error) => {
        console.log(error) //TODO
      })

      if (response) {
        setItem(response)
        setValue('code', response.code as string)
        setValue('enabled', response.enabled as boolean)
        setValue('locales', response.locales as Array<Locale>)
        setValue('exchangeRate', response.exchangeRate as number)
      }
    })()
  }, [])

  return (
    <Container>
      {item && item.id && (
        <Flex>
          <Flex1>
            <FormElement id="id" labelText="ID">
              <InputText id="id" disabled defaultValue={item.id} />
            </FormElement>
          </Flex1>
          <Flex1>
            <FormElement id="created-at" labelText="Created At">
              <Calendar
                id="created-at"
                disabled
                showIcon
                showTime
                value={new Date(item.createdAt as Date)}
              />
            </FormElement>
          </Flex1>
          <Flex1>
            <FormElement id="updated-at" labelText="Updated At">
              <Calendar
                id="updated-at"
                disabled
                showIcon
                showTime
                value={item.updatedAt && new Date(item.updatedAt)}
              />
            </FormElement>
          </Flex1>
        </Flex>
      )}

      <FormElement id="enabled" labelText="Enabled">
        <Checkbox
          onChange={(e) => {
            setItem({ ...item, enabled: e.checked })
            setValue('enabled', e.checked)
          }}
          checked={item?.enabled}
        ></Checkbox>
      </FormElement>
      <FormElement id="code" labelText="Currency Code">
        <Dropdown
          id="code"
          {...register('code')}
          value={item?.code}
          options={[
            {
              country: 'USA',
              currency: 'USD',
            },
            {
              country: 'UAE',
              currency: 'AED',
            },
            {
              country: 'EU',
              currency: 'EUR',
            },
            {
              country: 'Egypt',
              currency: 'EGP',
            },
            {
              country: 'UK',
              currency: 'GBP',
            },
            {
              country: 'Kuwait',
              currency: 'KWD',
            },
            {
              country: 'Lebanon',
              currency: 'LBP',
            },
            {
              country: 'Saudi Arabia',
              currency: 'SAR',
            },
            {
              country: 'Sudan',
              currency: 'SDG',
            },
            {
              country: 'Qatar',
              currency: 'QAR',
            },
            {
              country: 'Turkey',
              currency: 'TRY',
            },
          ]}
          optionLabel="currency"
          optionValue="currency"
          onChange={(e) => {
            setItem({ ...item, code: e.value })
            setValue('code', e.value)
          }}
        />
      </FormElement>

      <FormElement id="locales" labelText="Locale">
        <MultiSelect
          value={item?.locales}
          display="chip"
          options={[
            {
              code: 'us',
            },
            {
              code: 'ae',
            },
            {
              code: 'sa',
            },
            {
              code: 'eg',
            },
            {
              code: 'kw',
            },
            {
              code: 'bh',
            },
            {
              code: 'lb',
            },
            {
              code: 'tr',
            },
          ]}
          optionValue="code"
          optionLabel="code"
          id="locales"
          {...register('locales')}
          onChange={(e) => {
            setItem({ ...item, locales: e.value })
            setValue('locales', e.value)
          }}
        ></MultiSelect>
      </FormElement>

      <FormElement
        id="exchangeRate"
        labelText="Exchange Rate"
        errorText={errors.exchangeRate?.message}
      >
        <InputNumber
          value={item?.exchangeRate}
          id="exchangeRate"
          {...register('exchangeRate')}
          onChange={(e) => {
            setItem({ ...item, exchangeRate: e.value })
            setValue('exchangeRate', e.value)
          }}
          mode="decimal"
          minFractionDigits={2}
        />
      </FormElement>
    </Container>
  )
}

export default CurrencyDetail
