/* eslint-disable react/display-name */
import CreatorApi from 'api/creators/CreatorApi'
import {
  CreatorDetailRequest,
  CreatorDetailRequestSchema,
  CreatorDetailResponse,
} from 'api/creators/CreatorPayloads'
import { AxiosError } from 'axios'
import useYelaForm from 'hooks/useYelaForm'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import 'twin.macro'
import { BooleanColumnTemplate } from 'views/common/list_view/columns/ColumnTemplates'
import { BooleanFilterTemplate } from 'views/common/list_view/filters/BooleanFilter'
import ListView from 'views/common/list_view/ListView'
import CreatorView from './CreatorView'

const CreatorList = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const dataTable = useRef<DataTable>(null)
  const [, setEditItem] = useState<CreatorDetailResponse>()
  const [, setShowDialog] = useState<boolean>(false)
  const form = useYelaForm<CreatorDetailRequest>(CreatorDetailRequestSchema)

  return (
    <>
      <Toast ref={toast} />
      <ListView<CreatorDetailResponse, CreatorDetailRequest>
        dataTable={dataTable}
        viewName="Creator Detail"
        dataKey="id"
        maximized={true}
        columns={[
          {
            display: 'Image',
            field: 'photos.homePage',
            sortKey: 'photos.homePage',
            sortable: false,
            filterable: false,
            body: (rowData: CreatorDetailResponse) => {
              return (
                <img
                  src={`${rowData.photos?.homePage}?tr=r-max,w-100`}
                  style={{
                    borderRadius: '50%',
                    width: 48,
                    height: 48,
                    objectFit: 'cover',
                  }}
                />
              )
            },
          },
          {
            display: 'Slug',
            field: 'slug',
            sortKey: 'slug',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Fullname',
            field: 'detail.fullname.en',
            sortKey: 'name',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Availability',
            field: 'status.available',
            sortKey: 'available',
            sortable: true,
            filterable: true,
            body: (rowData: CreatorDetailResponse) => {
              return BooleanColumnTemplate(
                rowData?.status?.available as boolean
              )
            },
            filterBody: BooleanFilterTemplate({
              field: 'status.available',
              dataTable: dataTable,
            }),
          },
          {
            display: 'Enabled',
            field: 'status.enabled',
            sortKey: 'enabled',
            sortable: true,
            filterable: true,
            body: (rowData: CreatorDetailResponse) => {
              return BooleanColumnTemplate(rowData?.status?.enabled as boolean)
            },
            filterBody: BooleanFilterTemplate({
              field: 'status.enabled',
              dataTable: dataTable,
            }),
          },
        ]}
        defaultSort={{ sortBy: 'slug', desc: false }}
        onFetch={(p, s, f) => CreatorApi.findAll(p, s, f)}
        form={form}
        dialogContent={(id?: string) => <CreatorView id={id} form={form} />}
        onNew={async () => {
          setEditItem(undefined)
          setShowDialog(true)
        }}
        onDetails={async (item: CreatorDetailResponse) => {
          setEditItem(item)
          setShowDialog(true)
        }}
        onUpdate={async (id: string) => {
          await CreatorApi.update(id, form.getValues())
        }}
        onCreate={async () => {
          await CreatorApi.create(form.getValues())
        }}
        onDelete={async (items: CreatorDetailResponse[]) => {
          await CreatorApi.deleteAll(items.map((i) => i.id as string)).catch(
            (error: AxiosError) => {
              toast.current?.show({
                severity: 'error',
                summary: `Status: ${error.response?.status}`,
                detail: error.response?.data?.message,
                life: 3000,
              })
            }
          )
        }}
      />
    </>
  )
}

export default CreatorList
