import axios, { AxiosError, AxiosInstance } from 'axios'
import { AuthStateManager } from 'states/AuthState'
import AuthApi from './auth/AuthApi'

let requestInterceptorId: number | null = null
let responseInterceptorId: number | null = null

export function createClient(): AxiosInstance {
  const client = axios.create()
  configureClient(client)
  return client
}

export function configureClient(client: AxiosInstance): void {
  client.defaults.baseURL = process.env.REACT_APP_API_URL // TODO
  // client.defaults.baseURL = 'https://api-dashboard.yela-playground.com'
  //client.defaults.baseURL = 'http://localhost:3000/en/api/mock/'
  if (requestInterceptorId) {
    client.interceptors.request.eject(requestInterceptorId)
  }

  if (responseInterceptorId) {
    client.interceptors.response.eject(responseInterceptorId)
  }

  requestInterceptorId = client.interceptors.request.use((request) => {
    const state = AuthStateManager.readState()
    if (state) {
      request.headers['Authorization'] = `Bearer ${state.accessToken}`
    }

    return request
  })

  responseInterceptorId = client.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      if (error.response?.status !== 401) {
        return Promise.reject(error)
      }

      const state = AuthStateManager.readState()

      if (error.config.url == '/auth/refresh' || !state) {
        AuthStateManager.clearState()

        return Promise.reject(error)
      }

      return AuthApi.refresh({ token: state.refreshToken })
        .then((payload) => {
          AuthStateManager.storeState({
            refreshToken: state.refreshToken,
            ...payload,
          })

          const config = error.config
          config.headers['Authorization'] = `Bearer ${payload.accessToken}`

          return new Promise((resolve, reject) => {
            client
              .request(config)
              .then((response) => {
                resolve(response)
              })
              .catch((error) => {
                reject(error)
              })
          })
        })
        .catch((error) => {
          AuthStateManager.clearState()

          Promise.reject(error)

          window.location.href = '/login'
        })
    }
  )
}
