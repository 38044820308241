import tw, { styled } from 'twin.macro'

export const InputWrapper = styled.div`
  ${tw`w-full min-w-0 flex flex-col`}
`

export const InputLabel = styled.label`
  ${tw`ml-1 mb-1 truncate`};
`

export const InputError = styled.label`
  ${tw`mt-1 px-2`};
`
export const InputSpacer = styled.label`
  ${tw`mt-1`};
`
