import React, { ReactNode } from 'react'
import { AuthStateManager } from 'states/AuthState'
import 'twin.macro'
import Header from '../header/Header'
import Navigation from '../navigation/Navigation'
import { LayoutWrapper } from './Layout.styles'

const Layout = ({ children }: { children: ReactNode }): JSX.Element => {
  const state = AuthStateManager.readState()

  return (
    <LayoutWrapper>
      {state && (
        <Navigation
          items={[
            {
              text: 'Quick Create',
              icon: 'pi pi-angle-double-right',
              route: '/quick-create',
            },
            {
              text: 'Occasions',
              icon: 'pi pi-cloud',
              route: '/occasions',
            },
            {
              text: 'Currencies',
              icon: 'pi pi-wallet',
              route: '/currencies',
            },
            {
              text: 'Promotions',
              icon: 'pi pi-percentage',
              route: '/promotions',
            },
            {
              text: 'Orders',
              icon: 'pi pi-list',
              route: '/orders',
            },
            {
              text: 'Creator',
              icon: 'pi pi-key',
              route: '/celebrity-detail',
            },
            {
              text: 'User - Maintainers',
              icon: 'pi pi-user',
              route: '/maintainer',
            },
            {
              text: 'User - Fans',
              icon: 'pi pi-user',
              route: '/fan',
            },
            {
              text: 'User - Creators',
              icon: 'pi pi-user',
              route: '/creator',
            },
            {
              text: 'Push Notification - Creator',
              icon: 'pi pi-apple',
              route: '/pushnotification',
            },
          ]}
        />
      )}
      <div tw="w-full h-full">
        <Header />
        <div tw="w-full h-full p-6">{children}</div>
      </div>
    </LayoutWrapper>
  )
}

export default Layout
