/* eslint-disable react/display-name */
import CurrencyApi from 'api/currencies/CurrencyApi'
import {
  CurrencyRequest,
  CurrencyRequestSchema,
  CurrencyResponse,
} from 'api/currencies/CurrencyPayloads'
import { AxiosError } from 'axios'
import useYelaForm from 'hooks/useYelaForm'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import 'twin.macro'
import { BooleanColumnTemplate } from 'views/common/list_view/columns/ColumnTemplates'
import { BooleanFilterTemplate } from 'views/common/list_view/filters/BooleanFilter'
import ListView from 'views/common/list_view/ListView'
import CurrencyDetail from './CurrencyDetail'
import { CurrencyLocaleFilterTemplate } from './filters/CurrencyLocaleFilter'

const CurrencysList = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const dataTable = useRef<DataTable>(null)
  const [, setEditItem] = useState<CurrencyResponse>()
  const [, setShowDialog] = useState<boolean>(false)
  const form = useYelaForm<CurrencyRequest>(CurrencyRequestSchema)

  return (
    <>
      <Toast ref={toast} />
      <ListView<CurrencyResponse, CurrencyRequest>
        dataTable={dataTable}
        viewName="Currencies"
        dataKey="id"
        columns={[
          {
            display: 'Currency Code',
            field: 'code',
            sortKey: 'code',
            sortable: true,
            filterable: false,
          },
          {
            display: 'Locales',
            field: 'locales',
            sortKey: 'locales',
            sortable: true,
            filterable: true,
            body: (rowData: CurrencyResponse) => {
              return rowData.locales?.join(',')
            },
            filterBody: CurrencyLocaleFilterTemplate({
              dataTable: dataTable,
            }),
          },
          {
            display: 'Enabled',
            field: 'enabled',
            sortKey: 'enabled',
            sortable: true,
            filterable: true,
            body: (rowData: CurrencyResponse) => {
              return BooleanColumnTemplate(rowData?.enabled as boolean)
            },
            filterBody: BooleanFilterTemplate({
              field: 'enabled',
              dataTable: dataTable,
            }),
          },
          {
            display: 'Exchange Rate',
            field: 'exchangeRate',
            sortKey: 'exchangeRate',
            sortable: true,
            filterable: false,
          },
        ]}
        defaultSort={{ sortBy: 'id', desc: false }}
        onFetch={CurrencyApi.findAll}
        form={form}
        dialogContent={(id?: string) => <CurrencyDetail id={id} form={form} />}
        onNew={async () => {
          setEditItem(undefined)
          setShowDialog(true)
        }}
        onDetails={async (item: CurrencyResponse) => {
          setEditItem(item)
          setShowDialog(true)
        }}
        onUpdate={async (id: string) => {
          await CurrencyApi.update(id, form.getValues())
        }}
        onCreate={async () => {
          await CurrencyApi.create(form.getValues())
        }}
        onDelete={async (items: CurrencyResponse[]) => {
          await CurrencyApi.deleteAll(items.map((i) => i.id as string)).catch(
            (error: AxiosError) => {
              toast.current?.show({
                severity: 'error',
                summary: `Status: ${error.response?.status}`,
                detail: error.response?.data?.message,
                life: 3000,
              })
            }
          )
        }}
      />
    </>
  )
}

export default CurrencysList
