import Joi from 'joi'

export type CreatorUserContactRequest = {
  type: string
  name: string
  phone: string
}

export const CreatorUserContactRequestSchema =
  Joi.object<CreatorUserContactRequest>({
    type: Joi.string().min(2).required(),
    name: Joi.string().min(2).required(),
    phone: Joi.string().required(),
  })

export type CreatorUserContactResponse = {
  id?: string
  type?: string
  name?: string
  phone?: string
}
