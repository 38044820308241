import { Dropdown, DropdownChangeParams } from 'primereact/dropdown'
import React, { useState } from 'react'
import { TCurrencyLocaleFilter, TOptions } from './CurrencyLocaleFilter.types'

const CurrencyLocaleFilterTemplate = (
  props: TCurrencyLocaleFilter
): React.ReactNode => {
  const [locales, setLocales] = useState(null)
  const onLocaleChange = (e: DropdownChangeParams) => {
    props.dataTable.current?.filter(e.value, 'locales', 'equals')
    setLocales(e.value)
  }
  return (
    <Dropdown
      value={locales}
      options={[
        { name: 'ALL', value: '' } as TOptions,
        { name: 'US', value: 'US' } as TOptions,
        { name: 'UAE', value: 'UAE' } as TOptions,
        { name: 'Saudi Arabia', value: 'Saudi' } as TOptions,
        { name: 'Egypt', value: 'Egypt' } as TOptions,
        { name: 'Kuwait', value: 'Kuwait' } as TOptions,
        { name: 'Bahrain', value: 'Bahrain' } as TOptions,
        { name: 'Lebanon', value: 'Lebanon' } as TOptions,
        { name: 'Turkey', value: 'Turkey' } as TOptions,
      ]}
      optionLabel="name"
      optionValue="value"
      onChange={onLocaleChange}
      className="p-column-filter"
    />
  )
}

export { CurrencyLocaleFilterTemplate }
