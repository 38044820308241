import OrderApi from 'api/orders/OrderApi'
import {
  OrderRequest,
  OrderRequestSchema,
  OrderResponse,
} from 'api/orders/OrderPayloads'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import React, { useRef } from 'react'
import 'twin.macro'
import ListView from 'views/common/list_view/ListView'
import useYelaForm from 'hooks/useYelaForm'
import OrderDetail from './OrderDetail'
import { OrderStatusFilterTemplate } from './filters/OrderStatusFilter'
import { OrderCreatorFilterTemplate } from './filters/OrderCreatorFilter'
import { OrderMessageTypeFilterTemplate } from './filters/OrderMessageTypeFilter'

const OrderList = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const dataTable = useRef<DataTable>(null)
  const form = useYelaForm<OrderRequest>(OrderRequestSchema)
  const creatorBodyTemplate = (rowData: OrderResponse) => {
    return (
      <>
        <span>{rowData.creatorName}</span>
      </>
    )
  }
  return (
    <>
      <Toast ref={toast} />
      <ListView<OrderResponse, OrderRequest>
        dataTable={dataTable}
        viewName="Orders"
        dataKey="id"
        columns={[
          {
            display: 'Order Id',
            field: 'id',
            sortKey: 'id',
            sortable: true,
            filterable: false,
            style: { width: '20%' },
          },
          {
            display: 'Creator Name',
            field: 'creatorId',
            sortKey: 'creatorId',
            filterField: 'creatorId',
            sortable: true,
            filterable: true,
            body: creatorBodyTemplate,
            filterBody: OrderCreatorFilterTemplate({
              dataTable: dataTable,
            }),
          },
          {
            display: 'Email',
            field: 'email',
            sortKey: 'email',
            sortable: true,
            filterable: true,
            style: { width: 'fit-content' },
          },
          {
            display: 'Status',
            field: 'status',
            sortKey: 'status',
            sortable: true,
            filterable: true,
            filterBody: OrderStatusFilterTemplate({
              dataTable: dataTable,
            }),
          },
          {
            display: 'Message Type',
            field: 'messageType',
            sortKey: 'messageType',
            sortable: true,
            filterable: true,
            style: { width: '7%' },
            filterBody: OrderMessageTypeFilterTemplate({
              dataTable: dataTable,
            }),
          },

          {
            display: 'Remaining Hours',
            field: 'remainingHours',
            sortKey: 'remainingHours',
            sortable: true,
            filterable: false,
            style: { width: '7%' },
          },
          {
            display: 'Created At',
            field: 'createdAt',
            sortKey: 'createdAt',
            sortable: true,
            filterable: false,
          },
        ]}
        defaultSort={{ sortBy: 'createdAt', desc: true }}
        onFetch={OrderApi.findAll}
        form={form}
        dialogContent={(id?: string) => <OrderDetail id={id} form={form} />}
        showDialogAfterOperation={true}
        onUpdate={async (id: string) => {
          const response = await OrderApi.update(id, form.getValues())
          if (response) {
            toast.current?.show({
              severity: 'success',
              summary: `Order is updated successfully`,
              life: 3000,
            })
          }
        }}
      />
    </>
  )
}

export default OrderList
