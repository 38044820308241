import { Translated } from 'api/core/Translated'
import Joi from 'joi'

export type OccasionFilter = {
  order?: number
  slug?: string
  name?: string
}

export type OccasionRequest = {
  slug: string
  order: number
  name: Translated
  description: Translated
  enabled: boolean
  acceptsCustomValue: boolean
}

export const OccasionRequestSchema = Joi.object<OccasionRequest>({
  slug: Joi.string().min(2).required(),
  order: Joi.number().required(),
  name: Joi.object<Translated>({
    en: Joi.string().min(3),
    ar: Joi.string().min(3),
  }),
  description: Joi.object<Translated>({
    en: Joi.string().min(60),
    ar: Joi.string().min(60),
  }),
  enabled: Joi.boolean(),
  acceptsCustomValue: Joi.boolean(),
})

export type OccasionResponse = {
  id?: string
  slug?: string
  order?: number
  name?: Translated
  description?: Translated
  enabled?: boolean
  acceptsCustomValue?: boolean
  createdAt?: Date
  updatedAt?: Date
}
