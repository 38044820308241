import { Button } from 'primereact/button'
import { Column } from 'primereact/components/column/Column'
import { Dialog } from 'primereact/components/dialog/Dialog'
import { confirmDialog } from 'primereact/confirmdialog'
import {
  DataTable,
  DataTableFilterParams as FilterParams,
  DataTablePageParams as PageParams,
  DataTableSortParams as SortParams,
} from 'primereact/datatable'
import { Toolbar } from 'primereact/toolbar'
import React, { useEffect, useRef, useState } from 'react'
import {
  DetailsActionWrapper,
  HeaderText,
  ToolbarWrapper,
} from './ListView.styles'
import { DefaultPage, DefaultSort, ListViewProps } from './ListView.types'
import 'twin.macro'
import { AxiosError } from 'axios'
import { Toast } from 'primereact/toast'

const ListView = <E, R>({
  dataTable,
  viewName,
  dataKey,
  columns,
  defaultSort,
  form,
  maximized,
  showDialogAfterOperation,
  dialogContent,
  onFetch,
  // onNew,
  onCreate,
  onUpdate,
  // onDetails,
  onDelete,
  extraAction,
}: ListViewProps<E, R>): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState<PageParams>(DefaultPage)
  const [sort, setSort] = useState<SortParams>(DefaultSort(defaultSort))
  const [filters, setFilters] = useState<FilterParams>({ filters: {} })
  const [totalItems, setTotalItems] = useState<number>(0)
  const [items, setItems] = useState<E[]>([])
  const [selected, setSelected] = useState<E[]>([])
  const [editItem, setEditItem] = useState<E>()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const toast = useRef<Toast>(null)

  async function fetch() {
    setSelected([])
    setLoading(true)
    const response = await onFetch(
      { page: page.page, pageSize: page.rows },
      {
        sortBy: sort.sortField,
        desc: sort.sortOrder == -1,
      },
      Object.fromEntries(
        new Map(
          columns
            .filter((c) => c.sortable && c.sortKey)
            .map((c) => {
              return [c.sortKey!, filters.filters[c.field.toString()]?.value]
            })
        )
      )
    )

    setItems(response.items)
    setTotalItems(response.totalItems)
    setLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [page, sort, filters])

  const Header = <HeaderText>{viewName}</HeaderText>

  const ToolbarContent = (): JSX.Element => {
    return (
      <ToolbarWrapper>
        {onCreate && (
          <Button
            icon="pi pi-plus"
            className="p-button-primary"
            label="New"
            disabled={onCreate == null}
            onClick={async () => setShowDialog(true)}
          />
        )}
        {onDelete && (
          <Button
            icon="pi pi-trash"
            className="p-button-danger"
            label={`Delete [ ${selected.length} ]`}
            disabled={onDelete == null || selected.length <= 0}
            onClick={() => {
              if (!onDelete) return

              confirmDialog({
                message: `Are you sure you want to delete ${selected.length} records?`,
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: async () => {
                  await onDelete(selected)
                  await fetch()
                },
              })
            }}
          />
        )}
      </ToolbarWrapper>
    )
  }

  const DetailsActionContent = (item: E): JSX.Element => {
    return (
      <DetailsActionWrapper>
        <Button
          icon="pi pi-pencil"
          className="p-button-success p-button-rounded"
          onClick={() => {
            setEditItem(item)
            setShowDialog(true)
          }}
        />
        {extraAction && extraAction(item)}
      </DetailsActionWrapper>
    )
  }

  const paginatorLeft = (
    <Button
      type="button"
      icon="pi pi-refresh"
      className="p-button-text"
      onClick={fetch}
    />
  )

  async function onDialogClose() {
    form?.reset()
    setEditItem(undefined)
    setShowDialog(false)
    await fetch()
  }

  async function onDialogConfirm() {
    await form?.trigger()
    console.log(form?.formState.isValid)
    console.log(form?.formState.errors)
    if (!form?.formState.isValid) return

    if (editItem && onUpdate) {
      onUpdate((editItem as any).id)
        .then(async (v) => {
          if (!showDialogAfterOperation) await onDialogClose()
        })
        .catch((error: AxiosError) => {
          toast.current?.show({
            severity: 'error',
            summary: `Status: ${error.response?.status} ${
              error.response?.data.errors
                ? error.response.data.errors.join(' ')
                : ''
            }`,
            detail: error.response?.data?.message,
            sticky: true,
          })
        }) //TODO requires generic definitions from BE
    } else {
      onCreate?.()
        .then(async (v) => {
          if (!showDialogAfterOperation) await onDialogClose()
        })
        .catch((error: AxiosError) => {
          toast.current?.show({
            severity: 'error',
            summary: `Status: ${error.response?.status} ${
              error.response?.data.errors
                ? error.response.data.errors.join(' ')
                : ''
            }`,
            detail: error.response?.data?.message,
            sticky: true,
          })
        })
    }
  }

  const DialogFooter = (): JSX.Element => {
    return (
      <>
        <Button
          className="p-button-danger"
          icon="pi pi-times"
          label="Cancel"
          onClick={onDialogClose}
        />
        <Button
          className="p-button-primary"
          icon="pi pi-plus"
          label={editItem ? 'Update' : 'Create'}
          onClick={onDialogConfirm}
        />
      </>
    )
  }

  return (
    <>
      <Toast ref={toast} />
      <Toolbar left={Header} right={ToolbarContent} />
      <Dialog
        header={editItem ? 'Edit' : 'Create'}
        visible={showDialog}
        footer={DialogFooter}
        onHide={onDialogClose}
        maximized={maximized}
        maximizable
        modal
        closeOnEscape={false}
        tw="max-w-full w-1/2"
      >
        {dialogContent && dialogContent((editItem as any)?.id)}
      </Dialog>

      <DataTable
        ref={dataTable}
        lazy
        paginator
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorLeft}
        rows={page.rows}
        first={page.first}
        loading={loading}
        totalRecords={totalItems}
        dataKey={dataKey as string}
        value={items}
        selection={selected}
        onSelectionChange={(s) => setSelected(s.value)}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onPage={(p) => setPage(p)}
        onSort={(s) => setSort(s)}
        sortMode="single"
        sortField={sort.sortField}
        sortOrder={sort.sortOrder}
        filters={filters.filters}
        onFilter={(f) => setFilters(f)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        {columns.map((c) => {
          c.style = { ...c.style, overflow: 'hidden' }
          return (
            <Column
              key={c.field}
              field={c.field as string}
              filterField={c.filterField}
              header={c.display}
              sortable={c.sortable}
              filter={c.filterable}
              body={c.body}
              filterElement={c.filterBody}
              filterPlaceholder={`Search by ${c.display}`}
              filterMatchMode="custom"
              filterFunction={c.filterFunction}
              style={c.style}
            />
          )
        })}
        <Column body={DetailsActionContent} />
      </DataTable>
    </>
  )
}

export default ListView
