import 'twin.macro'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  NavigationHeader,
  NavigationList,
  NavigationWrapper,
  NavigatorItem,
} from './Navigation.styles'
import { NavigationItem } from './Navigation.types'
import { Button } from 'primereact/button'
import tw from 'twin.macro'

type NavigatorProps = {
  item: NavigationItem
  showName: boolean
}

const Navigator = ({ item, showName }: NavigatorProps): JSX.Element => {
  const history = useHistory()

  return (
    <NavigatorItem
      selected={history?.location?.pathname?.startsWith(item.route)}
      onClick={() => history?.push(item.route)}
    >
      <span className={item.icon} />
      {showName && <span>{item.text}</span>}
    </NavigatorItem>
  )
}

const Navigation = ({ items }: { items: NavigationItem[] }): JSX.Element => {
  const [collapsed, setCollapsed] = useState<boolean>(window.innerWidth < 1024)

  return (
    <>
      <div
        style={{
          width: !collapsed && window.innerWidth > 1024 ? '420px' : '50px',
          backgroundColor: 'black',
          height: '100vh',
        }}
      />

      <NavigationWrapper collapsed={collapsed} className="p-shadow-7">
        {!collapsed && (
          <NavigationHeader>
            <img src="/logo.svg" tw="h-12 w-12" />
            <span tw="flex-1 text-xl text-center">Yela Dashboard</span>
            <Button
              icon="pi pi-bars"
              className="p-button-rounded p-button-outlined"
              onClick={() => setCollapsed(!collapsed)}
            />
          </NavigationHeader>
        )}
        {collapsed && (
          <div css={tw`flex justify-center mt-6 mb-16`}>
            <Button
              icon="pi pi-bars"
              className="p-button-rounded p-button-outlined"
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
        )}

        <NavigationList>
          {items.map((item) => (
            <Navigator key={item.text} item={item} showName={!collapsed} />
          ))}
        </NavigationList>
      </NavigationWrapper>
    </>
  )
}

export default Navigation
