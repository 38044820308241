import { client } from 'api/api.root'
import { Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import {
  FanUserBulkInsert,
  FanUserFilter,
  FanUserRequest,
  FanUserResponse,
} from './FanUserPayloads'

export default class FanUserApi {
  static create = async (payload: FanUserRequest): Promise<FanUserResponse> => {
    const response = await client.post<FanUserResponse>('/users/fans', payload)

    return response.data
  }

  static update = async (
    id: string,
    payload: FanUserRequest
  ): Promise<FanUserResponse> => {
    const response = await client.put<FanUserResponse>(
      `/users/fans/${id}`,
      payload
    )

    return response.data
  }

  static delete = async (id: string): Promise<void> => {
    await client.delete(`/users/fans/${id}`)
  }

  static deleteAll = async (ids: string[]): Promise<void> => {
    await Promise.all(ids.map((id) => client.delete(`/users/fans/${id}`)))
  }

  static get = async (id: string): Promise<FanUserResponse> => {
    const response = await client.get<FanUserResponse>(`/users/fans/${id}`)

    return response.data
  }

  static findAll = async (
    pageable: Pageable,
    sortable: Sortable,
    filter: FanUserFilter
  ): Promise<PagedResponse<FanUserResponse>> => {
    const response = await client.get<PagedResponse<FanUserResponse>>(
      '/users/fans',
      { params: { ...pageable, ...sortable, ...filter } }
    )

    return response.data
  }

  static bulkInsert = async (users: FanUserBulkInsert[]): Promise<any> => {
    const response = await client.post<any>(`/users/fans/bulk`, users)
    return response.data
  }
}
