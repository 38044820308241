import Joi from 'joi'

export type MaintainerUserFilter = {
  name?: number
  email?: string
}

export type MaintainerUserRequest = {
  name: string
  email: string
  enabled: boolean
  permissions: Array<string>
}

export const MaintainerUserRequestSchema = Joi.object<MaintainerUserRequest>({
  name: Joi.string().min(2).required(),
  email: Joi.string().required(),
  enabled: Joi.boolean().required(),
  permissions: Joi.array().required(),
})

export type MaintainerUserResponse = {
  id?: string
  name?: string
  email?: string
  enabled?: boolean
  permissions?: Array<string>
  createdAt?: Date
  updatedAt?: Date
}
