import CreatorApi from 'api/creators/CreatorApi'
import { CreatorDetailResponse } from 'api/creators/CreatorPayloads'
import CreatorUserApi from 'api/users/creator/CreatorUserApi'
import {
  QuickCreateRequest,
  QuickCreateRequestSchema,
} from 'api/users/creator/CreatorUserPayloads'
import { AxiosError } from 'axios'
import FormElement from 'components/form/element/FormElement'
import useYelaForm from 'hooks/useYelaForm'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { HeaderText } from 'views/common/list_view/ListView.styles'

const Wrapper = tw.div`
flex flex-col justify-center max-w-2xl m-auto
`

const Header = tw.div`
flex space-x-4 justify-center items-center p-8 mt-8 mb-4
`

const Content = tw.div`
flex flex-col
`

const Row = tw.div`
flex justify-between space-x-4
`
const Footer = tw.div`
flex justify-between p-8
`

const QuickCreate = (): JSX.Element => {
  const toast = useRef<Toast>(null)

  const [option, setOption] = useState<
    'existing' | 'non-existing' | undefined
  >()

  const [selectedCreator, setSelectedCreator] = useState<
    CreatorDetailResponse | undefined
  >(undefined)

  const form = useYelaForm<QuickCreateRequest>(QuickCreateRequestSchema)
  const { isDirty, isValid } = form.formState

  const [creatorDetails, setCreatorDetails] = useState<CreatorDetailResponse[]>(
    []
  )

  const fetch = async () => {
    const response = await CreatorApi.findAllNotAssigned()
    if (response) {
      setCreatorDetails(response.items)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const reset = async () => {
    setOption(undefined)
    setSelectedCreator(undefined)
    form.setValue('name', { en: '', ar: '' })
    form.setValue('surname', { en: '', ar: '' })
    form.setValue('phone', '')
    form.setValue('videoPrice', 0.0)
    form.setValue('creatorId', undefined)
  }

  const onSelect = (value: CreatorDetailResponse) => {
    setSelectedCreator(value)
    form.setValue('name', value.detail?.name || { en: '', ar: '' })
    form.setValue('surname', value.detail?.surname || { en: '', ar: '' })
    form.setValue('creatorId', value.id)
    form.setValue('videoPrice', value.videoPricing?.price || 0)
    form.trigger()
  }

  const onCreate = async () => {
    CreatorUserApi.quickCreate(form.getValues())
      .then(() => {
        reset()
        fetch()
        toast.current?.show({
          severity: 'success',
          detail: `Created successfully`,
          sticky: true,
        })
      })
      .catch((error: AxiosError) => {
        console.log(error.response?.data?.errors)
        toast.current?.show({
          severity: 'error',
          detail: `${
            error.response?.data?.message
          } [ ${error.response?.data?.errors?.join(' ')} ]`,
          sticky: true,
        })
      })
  }

  return (
    <>
      <Toast ref={toast} />
      <Toolbar left={<HeaderText>Quickly Create a Creator</HeaderText>} />
      <Wrapper>
        <Header>
          <Dropdown
            id="creatorId"
            placeholder="Select a Celebrity..."
            options={creatorDetails}
            value={selectedCreator}
            optionLabel="slug"
            style={{ width: '250px' }}
            onChange={(e) => {
              reset()
              setOption('existing')
              onSelect(e.value)
            }}
          />
          <span>or</span>
          <Button
            onClick={() => {
              reset()
              setOption('non-existing')
            }}
          >
            Quick Create
          </Button>
        </Header>
        <Content>
          <Row>
            <FormElement
              id="phone"
              labelText="Phone Number"
              errorText={form.formState.errors.phone?.message}
            >
              <InputText
                id="phone"
                defaultValue={''}
                readOnly={option == undefined}
                placeholder={'+901112223344'}
                {...form.register('phone')}
              />
            </FormElement>

            <FormElement
              id="price"
              labelText="Video Price"
              errorText={form.formState.errors.videoPrice?.message}
            >
              <InputNumber
                id="price"
                mode="decimal"
                prefix="$"
                placeholder="$1"
                useGrouping={false}
                readOnly={option != 'non-existing'}
                {...form.register('videoPrice')}
                value={form.getValues('videoPrice')}
                onChange={(e) => {
                  form.setValue('videoPrice', e.value)
                }}
              />
            </FormElement>
          </Row>
          <Row>
            <FormElement
              id="firstNameEn"
              labelText="First Name - EN"
              errorText={form.formState.errors.name?.en?.message}
            >
              <InputText
                id="firstNameEn"
                defaultValue={''}
                readOnly={option != 'non-existing'}
                {...form.register('name.en')}
              />
            </FormElement>

            <FormElement
              id="lastNameEn"
              labelText="Last Name - EN"
              errorText={form.formState.errors.surname?.en?.message}
            >
              <InputText
                id="lastNameEn"
                defaultValue={''}
                readOnly={option != 'non-existing'}
                {...form.register('surname.en')}
              />
            </FormElement>
          </Row>
          <Row>
            <FormElement
              id="firstNameAr"
              labelText="First Name - AR"
              errorText={form.formState.errors.name?.ar?.message}
            >
              <InputText
                id="firstNameAr"
                defaultValue={''}
                readOnly={option != 'non-existing'}
                {...form.register('name.ar')}
              />
            </FormElement>

            <FormElement
              id="lastNameAr"
              labelText="Last Name - AR"
              errorText={form.formState.errors.surname?.ar?.message}
            >
              <InputText
                id="lastNameAr"
                defaultValue={''}
                readOnly={option != 'non-existing'}
                {...form.register('surname.ar')}
              />
            </FormElement>
          </Row>
        </Content>
        <Footer>
          <Button
            className="p-button-outlined p-button-danger"
            disabled={option == undefined}
            onClick={() => reset()}
          >
            Reset
          </Button>
          <Button
            className="p-button-success"
            disabled={option == undefined}
            onClick={async () => {
              await form.trigger()

              console.log(form.getValues())
              console.log(isDirty, isValid, form.formState.errors)
              if (!isDirty || !isValid) return

              onCreate()
            }}
          >
            Create
          </Button>
        </Footer>
      </Wrapper>
    </>
  )
}

export default QuickCreate
