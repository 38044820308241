import { Translated } from 'api/core/Translated'
import OccasionApi from 'api/occasions/OccasionApi'
import {
  OccasionRequest,
  OccasionResponse,
} from 'api/occasions/OccasionPayloads'
import FormElement from 'components/form/element/FormElement'
import { required } from 'joi'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import tw from 'twin.macro'

const Container = tw.div`
  px-4
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

type TOccasionDetail = {
  id?: string
  form: UseFormReturn<OccasionRequest>
}

const OccasionDetail = ({
  id,
  form: {
    register,
    setValue,
    formState: { errors },
  },
}: TOccasionDetail): JSX.Element => {
  const [item, setItem] = useState<OccasionResponse>()

  useEffect(() => {
    if (!id) return
    ;(async () => {
      const response = await OccasionApi.get(id).catch((error) => {
        console.log(error) //TODO
      })

      if (response) {
        setItem(response)
        setValue('slug', response.slug as string)
        setValue('name', response.name as Translated)
        setValue('order', response.order as number)
        setValue('description', response.description as Translated)
        setValue('enabled', response.enabled as boolean)
        setValue('acceptsCustomValue', response.acceptsCustomValue as boolean)
      }
    })()
  }, [])

  return (
    <Container>
      {item && item.id && (
        <Flex>
          <Flex1>
            <FormElement id="id" labelText="ID">
              <InputText id="id" disabled defaultValue={item.id} />
            </FormElement>
          </Flex1>
          <Flex1>
            <FormElement id="created-at" labelText="Created At">
              <Calendar
                id="created-at"
                disabled
                showIcon
                showTime
                value={new Date(item.createdAt as Date)}
              />
            </FormElement>
          </Flex1>
          <Flex1>
            <FormElement id="updated-at" labelText="Updated At">
              <Calendar
                id="updated-at"
                disabled
                showIcon
                showTime
                value={item.updatedAt && new Date(item.updatedAt)}
              />
            </FormElement>
          </Flex1>
        </Flex>
      )}
      <FormElement id="slug" labelText="Slug" errorText={errors.slug?.message}>
        <InputText
          id="slug"
          {...register('slug')}
          readOnly={item && item.id ? true : false}
        />
      </FormElement>

      <FormElement
        id="name-en"
        labelText="Name - EN"
        errorText={errors.name?.en?.message}
      >
        <InputText id="name-en" dir="auto" {...register('name.en')} />
      </FormElement>

      <FormElement
        id="description-en"
        labelText="Description - EN"
        errorText={errors.description?.en?.message}
      >
        <InputTextarea
          id="description-en"
          dir="auto"
          rows={5}
          {...register('description.en')}
        />
      </FormElement>

      <FormElement
        id="name-ar"
        labelText="Name - AR"
        errorText={errors.name?.ar?.message}
      >
        <InputText id="name-ar" dir="auto" {...register('name.ar')} />
      </FormElement>

      <FormElement
        id="description-ar"
        labelText="Description - AR"
        errorText={errors.description?.ar?.message}
      >
        <InputTextarea
          id="description-ar"
          dir="auto"
          rows={5}
          {...register('description.ar')}
        />
      </FormElement>
      <FormElement
        id="order"
        labelText="Order"
        errorText={errors.order?.message}
      >
        <InputNumber
          value={item?.order}
          id="order"
          {...register('order')}
          onChange={(e) => {
            setItem({
              ...item,
              order: e.value,
            })
            setValue('order', e.value)
          }}
        />
      </FormElement>
      <Flex>
        <Flex1>
          <FormElement id="enabled" labelText="Enabled">
            <Checkbox
              onChange={(e) => {
                setItem({ ...item, enabled: e.checked })
                setValue('enabled', e.checked)
              }}
              checked={item?.enabled}
            ></Checkbox>
          </FormElement>
        </Flex1>
        <Flex1>
          <FormElement id="acceptsCustomValue" labelText="Accepts Custom Value">
            <Checkbox
              onChange={(e) => {
                setItem({ ...item, acceptsCustomValue: e.checked })
                setValue('acceptsCustomValue', e.checked)
              }}
              checked={item?.acceptsCustomValue}
            ></Checkbox>
          </FormElement>
        </Flex1>
      </Flex>
    </Container>
  )
}

export default OccasionDetail
