import MaintainerUserApi from 'api/users/maintainer/MaintainerUserApi'
import {
  MaintainerUserRequest,
  MaintainerUserResponse,
} from 'api/users/maintainer/MaintainerUserPayloads'
import FormElement from 'components/form/element/FormElement'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import tw from 'twin.macro'

const Container = tw.div`
  px-4
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

type TMaintainerUserDetail = {
  id?: string
  form: UseFormReturn<MaintainerUserRequest>
}

const MaintainerUserDetail = ({
  id,
  form: {
    register,
    setValue,
    formState: { errors },
  },
}: TMaintainerUserDetail): JSX.Element => {
  const [item, setItem] = useState<MaintainerUserResponse>()

  useEffect(() => {
    if (!id) return
    ;(async () => {
      const response = await MaintainerUserApi.get(id).catch((error) => {
        console.log(error) //TODO
      })

      if (response) {
        setItem(response)
        setValue('name', response.name as string)
        setValue('email', response.email as string)
        setValue('enabled', response.enabled as boolean)
        setValue('permissions', response.permissions as Array<string>)
      }
    })()
  }, [])

  return (
    <Container>
      {item && item.id && (
        <>
          <Flex>
            <Flex1>
              <FormElement id="id" labelText="ID">
                <InputText id="id" disabled defaultValue={item.id} />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="created-at" labelText="Created At">
                <Calendar
                  id="created-at"
                  disabled
                  showIcon
                  showTime
                  value={new Date(item.createdAt as Date)}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="updated-at" labelText="Updated At">
                <Calendar
                  id="updated-at"
                  disabled
                  showIcon
                  showTime
                  value={item.updatedAt && new Date(item.updatedAt)}
                />
              </FormElement>
            </Flex1>
          </Flex>
        </>
      )}
      <div>
        <FormElement id="enabled" labelText="Enabled">
          <Checkbox
            onChange={(e) => {
              setItem({ ...item, enabled: e.checked })
              setValue('enabled', e.checked)
            }}
            checked={item?.enabled}
          ></Checkbox>
        </FormElement>
        <FormElement
          id="name"
          labelText="Name"
          errorText={errors.name?.message}
        >
          <InputText id="name" {...register('name')} />
        </FormElement>

        <FormElement
          id="email"
          labelText="Email"
          errorText={errors.email?.message}
        >
          <InputText
            id="email"
            dir="auto"
            {...register('email')}
            defaultValue={item?.email}
          />
        </FormElement>
        <FormElement id="permissions" labelText="Permissions">
          <MultiSelect
            value={item?.permissions}
            display="chip"
            options={[
              'Admin:Privilege',
              'Promotions:Read',
              'Promotions:Write',
              'Creators:Read',
              'Creators:Write',
              'Currencies:Read',
              'Currencies:Write',
              'Occasions:Read',
              'Occasions:Write',
              'CreatorUsers:Read',
              'CreatorUsers:Write',
              'FanUsers:Read',
              'FanUsers:Write',
            ]}
            id="permissions"
            {...register('permissions')}
            onChange={(e) => {
              setItem({ ...item, permissions: e.value })
              setValue('permissions', e.value)
            }}
          ></MultiSelect>
        </FormElement>
      </div>
    </Container>
  )
}

export default MaintainerUserDetail
