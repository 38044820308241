import React from 'react'
import {
  InputError,
  InputLabel,
  InputSpacer,
  InputWrapper,
} from './FormElement.styles'
import { TFormElementProps } from './FormElement.types'

const FormElement = ({
  id,
  labelText,
  errorText,
  children,
}: TFormElementProps): JSX.Element => {
  return (
    <InputWrapper className="p-field">
      {labelText && (
        <InputLabel htmlFor={id} title={labelText}>
          {labelText}
        </InputLabel>
      )}
      {children}
      {!errorText && <InputSpacer>&nbsp;</InputSpacer>}
      {errorText && (
        <InputError className="p-error" title={errorText}>
          {errorText}
        </InputError>
      )}
    </InputWrapper>
  )
}

export default FormElement
