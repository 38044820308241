import CreatorApi from 'api/creators/CreatorApi'
import {
  CreatorDetail,
  CreatorDetailRequest,
  CreatorDetailResponse,
  CreatorHomePage,
  CreatorLimiting,
  CreatorPhotos,
  CreatorPricing,
  CreatorStatus,
} from 'api/creators/CreatorPayloads'
import FormElement from 'components/form/element/FormElement'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'primereact/checkbox'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { InputTextarea } from 'primereact/inputtextarea'
import tw from 'twin.macro'

const Container = tw.div`
  px-4
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

const Flex2 = tw.div`
  flex-2
`

const Flex3 = tw.div`
  flex-3
`

type TCreatorDetailDetail = {
  id?: string
  form: UseFormReturn<CreatorDetailRequest>
}

const CreatorView = ({
  id,
  form: {
    register,
    setValue,
    formState: { errors },
  },
}: TCreatorDetailDetail): JSX.Element => {
  const [item, setItem] = useState<CreatorDetailResponse>()

  useEffect(() => {
    if (!id) return
    ;(async () => {
      const response = await CreatorApi.get(id).catch((error) => {
        console.log(error) //TODO
      })

      if (response) {
        setItem(response)
        setValue('slug', response.slug as string)
        setValue('status', response.status as CreatorStatus)
        setValue('detail', response.detail as CreatorDetail)
        setValue('photos', response.photos as CreatorPhotos)
        setValue('limiting', response.limiting as CreatorLimiting)
        setValue('pages', response.pages as CreatorHomePage)
        setValue('audioPricing', response.audioPricing as CreatorPricing)
        setValue('videoPricing', response.videoPricing as CreatorPricing)
      }
    })()
  }, [])

  return (
    <Container>
      <div>
        {item && item.id && (
          <Flex>
            <Flex1>Uneditable</Flex1>
            <Flex3>
              <Flex>
                <Flex1>
                  <FormElement id="id" labelText="ID">
                    <InputText id="id" disabled defaultValue={item.id} />
                  </FormElement>
                </Flex1>
                <Flex1>
                  <FormElement id="created-at" labelText="Created At">
                    <Calendar
                      id="created-at"
                      disabled
                      showIcon
                      showTime
                      value={new Date(item.createdAt as Date)}
                    />
                  </FormElement>
                </Flex1>
                <Flex1>
                  <FormElement id="updated-at" labelText="Updated At">
                    <Calendar
                      id="updated-at"
                      disabled
                      showIcon
                      showTime
                      value={item.updatedAt && new Date(item.updatedAt)}
                    />
                  </FormElement>
                </Flex1>
              </Flex>
            </Flex3>
          </Flex>
        )}
      </div>
      <Flex>
        <Flex1>Main</Flex1>
        <Flex3>
          <Flex>
            <Flex1>
              <FormElement id="slug" labelText="Slug">
                <InputText
                  id="slug"
                  readOnly={item && item.id ? true : false}
                  {...register('slug')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="pages-order"
                labelText="Homepage Order"
                errorText={errors.pages?.homePageOrder?.message}
              >
                <InputNumber
                  value={item?.pages?.homePageOrder}
                  id="pages-order"
                  {...register('pages.homePageOrder')}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      pages: {
                        homePage: item?.pages?.homePage || false,
                        homePageOrder: e.value,
                      },
                    })
                    setValue('pages.homePageOrder', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="pages-show" labelText="Show at Homepage">
                <Checkbox
                  onChange={(e) => {
                    setItem({
                      ...item,
                      pages: {
                        homePage: e.checked,
                        homePageOrder: item?.pages?.homePageOrder || 1,
                      },
                    })
                    setValue('pages.homePage', e.checked)
                  }}
                  checked={item?.pages?.homePage}
                ></Checkbox>
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement
                id="video-limiting"
                labelText="Video Limiting"
                errorText={errors.limiting?.videoLimit?.limit?.message}
              >
                <InputNumber
                  value={item?.limiting?.videoLimit?.limit}
                  id="video-limiting"
                  {...register('limiting.videoLimit.limit')}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      limiting: {
                        videoLimit: {
                          limit: e.value,
                          enabled: item?.limiting?.videoLimit?.enabled ?? false,
                        },
                        audioLimit: item?.limiting?.audioLimit ?? {
                          limit: 0,
                          enabled: false,
                        },
                      },
                    })
                    setValue('limiting.videoLimit.limit', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="video-limit-enabled"
                labelText="Video Limit Enabled"
              >
                <Checkbox
                  onChange={(e) => {
                    setItem({
                      ...item,
                      limiting: {
                        videoLimit: {
                          limit: item?.limiting?.videoLimit.limit ?? 0,
                          enabled: e.checked,
                        },
                        audioLimit: item?.limiting?.audioLimit ?? {
                          limit: 0,
                          enabled: false,
                        },
                      },
                    })
                    setValue('limiting.videoLimit.enabled', e.checked)
                  }}
                  checked={item?.limiting?.videoLimit.enabled}
                ></Checkbox>
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="audio-limiting"
                labelText="Audio Limiting"
                errorText={errors.limiting?.audioLimit?.limit?.message}
              >
                <InputNumber
                  value={item?.limiting?.audioLimit?.limit}
                  id="weekly-limiting"
                  {...register('limiting.audioLimit.limit')}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      limiting: {
                        audioLimit: {
                          limit: e.value,
                          enabled: item?.limiting?.audioLimit?.enabled ?? false,
                        },
                        videoLimit: item?.limiting?.videoLimit ?? {
                          limit: 0,
                          enabled: false,
                        },
                      },
                    })
                    setValue('limiting.audioLimit.limit', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="audio-limit-enabled"
                labelText="Audio Limit Enabled"
              >
                <Checkbox
                  onChange={(e) => {
                    setItem({
                      ...item,
                      limiting: {
                        audioLimit: {
                          limit: item?.limiting?.audioLimit.limit ?? 0,
                          enabled: e.checked,
                        },
                        videoLimit: item?.limiting?.videoLimit ?? {
                          limit: 0,
                          enabled: false,
                        },
                      },
                    })
                    setValue('limiting.audioLimit.enabled', e.checked)
                  }}
                  checked={item?.limiting?.audioLimit.enabled}
                ></Checkbox>
              </FormElement>
            </Flex1>
          </Flex>
        </Flex3>
      </Flex>
      <Flex>
        <Flex1>Status</Flex1>
        <Flex3>
          <Flex>
            <Flex1>
              <FormElement id="enabled" labelText="Enabled">
                <Checkbox
                  onChange={(e) => {
                    setItem({
                      ...item,
                      status: {
                        enabled: e.checked,
                        available: item?.status?.available ? true : false,
                        goingFast: item?.status?.goingFast ? true : false,
                      },
                    })
                    setValue('status.enabled', e.checked)
                  }}
                  checked={item?.status?.enabled}
                ></Checkbox>
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="available" labelText="Available">
                <Checkbox
                  onChange={(e) => {
                    setItem({
                      ...item,
                      status: {
                        available: e.checked,
                        enabled: item?.status?.enabled ? true : false,
                        goingFast: item?.status?.goingFast ? true : false,
                      },
                    })
                    setValue('status.available', e.checked)
                  }}
                  checked={item?.status?.available}
                ></Checkbox>
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="goingFast" labelText="Going Fast">
                <Checkbox
                  onChange={(e) => {
                    setItem({
                      ...item,
                      status: {
                        goingFast: e.checked,
                        enabled: item?.status?.enabled ? true : false,
                        available: item?.status?.available ? true : false,
                      },
                    })
                    setValue('status.goingFast', e.checked)
                  }}
                  checked={item?.status?.goingFast}
                ></Checkbox>
              </FormElement>
            </Flex1>
          </Flex>
        </Flex3>
      </Flex>
      <Flex>
        <Flex1>Details</Flex1>
        <Flex3>
          <Flex>
            <Flex1>
              <FormElement
                id="name-en"
                labelText="Name - EN"
                errorText={errors.detail?.name?.en?.message}
              >
                <InputText
                  id="name-en"
                  dir="auto"
                  {...register('detail.name.en')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="name-ar"
                labelText="Name - AR"
                errorText={errors.detail?.name?.ar?.message}
              >
                <InputText
                  id="name-ar"
                  dir="auto"
                  {...register('detail.name.ar')}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement
                id="surname-en"
                labelText="Surname - EN"
                errorText={errors.detail?.surname?.en?.message}
              >
                <InputText
                  id="surname-en"
                  dir="auto"
                  {...register('detail.surname.en')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="surname-ar"
                labelText="Surname - AR"
                errorText={errors.detail?.surname?.ar?.message}
              >
                <InputText
                  id="surname-ar"
                  dir="auto"
                  {...register('detail.surname.ar')}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement
                id="fullname-en"
                labelText="Fullname - EN"
                errorText={errors.detail?.fullname?.en?.message}
              >
                <InputText
                  id="fullname-en"
                  dir="auto"
                  {...register('detail.fullname.en')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="fullname-ar"
                labelText="Fullname - AR"
                errorText={errors.detail?.fullname?.ar?.message}
              >
                <InputText
                  id="fullname-ar"
                  dir="auto"
                  {...register('detail.fullname.ar')}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement
                id="description-en"
                labelText="Description - EN"
                errorText={errors.detail?.description?.en?.message}
              >
                <InputTextarea
                  id="description-en"
                  dir="auto"
                  {...register('detail.description.en')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="description-ar"
                labelText="Description - AR"
                errorText={errors.detail?.description?.ar?.message}
              >
                <InputTextarea
                  id="description-ar"
                  dir="auto"
                  {...register('detail.description.ar')}
                />
              </FormElement>
            </Flex1>
          </Flex>
        </Flex3>
      </Flex>
      <Flex>
        <Flex1>Photos</Flex1>
        <Flex3>
          <Flex>
            <Flex1>
              <FormElement
                id="homepage-photo"
                labelText="Homepage - Photo"
                errorText={errors.photos?.homePage?.message}
              >
                <InputText
                  id="homepage-photo"
                  dir="auto"
                  {...register('photos.homePage')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="consumptionPage-photo"
                labelText="Consumption page - Photo"
                errorText={errors.photos?.consumptionPage?.message}
              >
                <InputText
                  id="consumptionPage-photo"
                  dir="auto"
                  {...register('photos.consumptionPage')}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement
                id="profilepage-photo"
                labelText="Profile page - Photo"
                errorText={errors.photos?.profilePage?.message}
              >
                <InputText
                  id="profilepage-photo"
                  dir="auto"
                  {...register('photos.profilePage')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="profilepage-video"
                labelText="Profile page - Video"
                errorText={errors.photos?.profilePageVideo?.message}
              >
                <InputText
                  id="profilepage-video"
                  dir="auto"
                  {...register('photos.profilePageVideo')}
                />
              </FormElement>
            </Flex1>
          </Flex>
        </Flex3>
      </Flex>
      <Flex>
        <Flex1>Video</Flex1>
        <Flex3>
          <Flex>
            <Flex1>
              <FormElement
                id="video-price"
                labelText="Video Price"
                errorText={errors.videoPricing?.price?.message}
              >
                <InputNumber
                  value={item?.videoPricing?.price}
                  id="video-price"
                  {...register('videoPricing.price')}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      videoPricing: {
                        enabled: item?.videoPricing?.enabled || false,
                        price: e.value,
                        commission: item?.videoPricing?.commission || 0,
                      },
                    })
                    setValue('videoPricing.price', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="video-commission"
                labelText="Video Commission"
                errorText={errors.videoPricing?.commission?.message}
              >
                <InputNumber
                  value={item?.videoPricing?.commission}
                  id="video-comission"
                  {...register('videoPricing.commission')}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      videoPricing: {
                        enabled: item?.videoPricing?.enabled || false,
                        price: item?.videoPricing?.price || 0,
                        commission: e.value,
                      },
                    })
                    setValue('videoPricing.commission', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="video-enabled" labelText="Video Enabled">
                <Checkbox
                  onChange={(e) => {
                    setItem({
                      ...item,
                      videoPricing: {
                        enabled: e.checked,
                        price: item?.videoPricing?.price || 0,
                        commission: item?.videoPricing?.commission || 0,
                      },
                    })
                    setValue('videoPricing.enabled', e.checked)
                  }}
                  checked={item?.videoPricing?.enabled}
                ></Checkbox>
              </FormElement>
            </Flex1>
          </Flex>
        </Flex3>
      </Flex>
      <Flex>
        <Flex1>Audio</Flex1>
        <Flex3>
          <Flex>
            <Flex1>
              <FormElement
                id="audio-price"
                labelText="Audio Price"
                errorText={errors.audioPricing?.price?.message}
              >
                <InputNumber
                  value={item?.audioPricing?.price}
                  id="audio-price"
                  {...register('audioPricing.price')}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      audioPricing: {
                        enabled: item?.audioPricing?.enabled || false,
                        price: e.value,
                        commission: item?.audioPricing?.commission || 0,
                      },
                    })
                    setValue('audioPricing.price', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="audio-commission"
                labelText="Audio Commission"
                errorText={errors.audioPricing?.commission?.message}
              >
                <InputNumber
                  value={item?.audioPricing?.commission}
                  id="audio-comission"
                  {...register('audioPricing.commission')}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      audioPricing: {
                        enabled: item?.audioPricing?.enabled || false,
                        price: item?.audioPricing?.price || 0,
                        commission: e.value,
                      },
                    })
                    setValue('audioPricing.commission', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="audio-enabled" labelText="Audio Enabled">
                <Checkbox
                  onChange={(e) => {
                    setItem({
                      ...item,
                      audioPricing: {
                        enabled: e.checked,
                        price: item?.audioPricing?.price || 0,
                        commission: item?.audioPricing?.commission || 0,
                      },
                    })
                    setValue('audioPricing.enabled', e.checked)
                  }}
                  checked={item?.audioPricing?.enabled}
                ></Checkbox>
              </FormElement>
            </Flex1>
          </Flex>
        </Flex3>
      </Flex>
    </Container>
  )
}

export default CreatorView
