import { AxiosInstance } from 'axios'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthStateManager } from 'states/AuthState'
import { createClient } from './api.config'

export const client: AxiosInstance = createClient()

type ApiRoot = {
  children: JSX.Element
}

const ApiRoot = ({ children }: ApiRoot): JSX.Element => {
  const history = useHistory()

  useEffect(() => {
    if (AuthStateManager.readState() == null) {
      history.push('/login')
    }
  })
  return children
}

export default ApiRoot
