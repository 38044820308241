import PromotionApi from 'api/promotions/PromotionApi'
import {
  PromotionRequest,
  PromotionResponse,
} from 'api/promotions/PromotionPayloads'
import FormElement from 'components/form/element/FormElement'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import tw from 'twin.macro'
import { MultiSelect } from 'primereact/multiselect'
import { CreatorDetailResponse } from 'api/creators/CreatorPayloads'
import CreatorApi from 'api/creators/CreatorApi'

const Container = tw.div`
  px-4
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

type TPromotionDetail = {
  id?: string
  form: UseFormReturn<PromotionRequest>
}

const PromotionDetail = ({
  id,
  form: {
    register,
    setValue,
    formState: { errors },
  },
}: TPromotionDetail): JSX.Element => {
  const [item, setItem] = useState<PromotionResponse>()
  const [allCelebs, setAllCelebs] = useState<CreatorDetailResponse[]>([])
  const [selectedCelebs, setSelectedCelebs] = useState<CreatorDetailResponse[]>(
    []
  )

  useEffect(() => {
    if (!id) return
    ;(async () => {
      const response = await PromotionApi.get(id).catch((error) => {
        console.log(error) //TODO
      })

      if (response) {
        setItem(response)
        setValue('email', response.email as string)
        setValue('amount', response.amount as number)
        setValue('type', response.type as string)
        setValue('language', response.language as string)
        setValue('validUntil', response.validUntil as Date)
        setValue('validAfter', response.validAfter as Date)
        setValue('creatorIds', response.creatorIds as string[])
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const response = await CreatorApi.findAll(
        {
          page: 0,
          pageSize: 1000,
        },
        {
          desc: false,
          sortBy: 'name',
        },
        {}
      ).catch((error) => {
        console.log(error) //TODO
      })

      if (response) {
        setAllCelebs(response.items)

        if (item?.creatorIds) {
          const selected = item?.creatorIds
            .map((id) => {
              return response.items.find((item) => item.id == id)
            })
            .filter((i) => i != null)

          setSelectedCelebs(selected as CreatorDetailResponse[])
        } else {
          setSelectedCelebs([])
        }
      }
    })()
  }, [item])

  const itemTemplate = (option: CreatorDetailResponse) => {
    return <div>{option?.detail?.fullname?.en}</div>
  }

  return (
    <Container>
      {item && item.id && (
        <Flex>
          <Flex1>
            <FormElement id="id" labelText="ID">
              <InputText id="id" disabled defaultValue={item.id} />
            </FormElement>
          </Flex1>
        </Flex>
      )}

      <FormElement
        id="email"
        labelText="Email"
        errorText={errors.email?.message}
      >
        <InputText id="email" dir="auto" {...register('email')} />
      </FormElement>

      {item && item.id && (
        <FormElement id="code" labelText="Code">
          <InputText id="code" value={item.code} dir="auto" disabled />
        </FormElement>
      )}

      <FormElement id="type" labelText="Type" errorText={errors.type?.message}>
        <Dropdown
          id="type"
          {...register('type')}
          value={item?.type}
          options={['fixed-amount', 'percentage']}
          onChange={(e) => {
            setItem({ ...item, type: e.value })
            setValue('type', e.value)
          }}
        />
      </FormElement>
      <FormElement
        id="amount"
        labelText="Amount"
        errorText={errors.amount?.message}
      >
        <InputNumber
          value={item?.amount}
          id="amount"
          {...register('amount')}
          onChange={(e) => {
            setItem({ ...item, amount: e.value })
            setValue('amount', e.value)
          }}
        />
      </FormElement>
      <FormElement
        id="language"
        labelText="Language"
        errorText={errors.language?.message}
      >
        <Dropdown
          id="language"
          {...register('language')}
          value={item?.language}
          options={['en', 'ar']}
          onChange={(e) => {
            setItem({ ...item, language: e.value })
            setValue('language', e.value)
          }}
        />
      </FormElement>
      <FormElement
        id="validAfter"
        labelText="Valid After"
        errorText={errors.validAfter?.message}
      >
        <Calendar
          id="validAfter"
          {...register('validAfter')}
          value={new Date(item?.validAfter as Date)}
          showIcon
          onChange={(e) => {
            setItem({ ...item, validAfter: e.value as Date })
            setValue('validAfter', e.value as Date)
          }}
        />
      </FormElement>
      <FormElement
        id="validUntil"
        labelText="Valid Until"
        errorText={errors.validUntil?.message}
      >
        <Calendar
          id="validUntil"
          {...register('validUntil')}
          value={new Date(item?.validUntil as Date)}
          showIcon
          onChange={(e) => {
            setItem({ ...item, validUntil: e.value as Date })
            setValue('validUntil', e.value as Date)
          }}
        />
      </FormElement>
      <FormElement id="creatorIds" labelText="Creators">
        <MultiSelect
          value={selectedCelebs}
          options={allCelebs}
          itemTemplate={itemTemplate}
          optionLabel={'detail.fullname.en'}
          display="chip"
          onChange={(e) => {
            const selected = e.value as CreatorDetailResponse[]
            const selectedIds =
              selected.length == 0 ? undefined : selected.map((s) => s.id!)

            setSelectedCelebs(selected)
            setItem({
              ...item,
              creatorIds: selectedIds,
            })
            setValue('creatorIds', selectedIds)
          }}
        ></MultiSelect>
      </FormElement>
    </Container>
  )
}

export default PromotionDetail
