import CreatorApi from 'api/creators/CreatorApi'
import { CreatorDetailResponse } from 'api/creators/CreatorPayloads'
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown'
import React, { useEffect, useState } from 'react'
import { TOrderCreatorFilter } from './OrderCreatorFilter.types'

const OrderCreatorFilterTemplate = (
  props: TOrderCreatorFilter
): React.ReactNode => {
  const [creator, setCreator] = useState<CreatorDetailResponse>()
  const [creatorUsers, setCreatorUsers] = useState<CreatorDetailResponse[]>()
  const onCreatorChange = (e: DropdownChangeParams) => {
    props.dataTable.current?.filter(e.value.id, 'creatorId', 'equals')
    setCreator(e.value)
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const response = await CreatorApi.findAll(
        {
          page: 0,
          pageSize: 1000,
        },
        {
          desc: false,
          sortBy: 'name',
        },
        {}
      )
      if (response) {
        response.items.unshift({
          id: '',
          detail: {
            fullname: {
              en: 'ALL',
              ar: 'ALL',
            },
            name: {
              en: 'ALL',
              ar: 'ALL',
            },
            surname: {
              en: 'ALL',
              ar: 'ALL',
            },
            description: {
              en: '',
              ar: '',
            },
          },
        })
        setCreatorUsers(response.items)
      }
    })()
  }, [])
  return (
    <Dropdown
      value={creator}
      options={creatorUsers}
      optionLabel="detail.fullname.en"
      onChange={onCreatorChange}
      className="p-column-filter"
    />
  )
}

export { OrderCreatorFilterTemplate }
