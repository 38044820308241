import { client } from 'api/api.root'
import { Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import {
  CreatorUserFilter,
  CreatorUserRequest,
  CreatorUserResponse,
  QuickCreateRequest,
} from './CreatorUserPayloads'

export default class CreatorUserApi {
  static create = async (
    payload: CreatorUserRequest
  ): Promise<CreatorUserResponse> => {
    const response = await client.post<CreatorUserResponse>(
      '/users/creators',
      payload
    )

    return response.data
  }

  static quickCreate = async (payload: QuickCreateRequest): Promise<any> => {
    const response = await client.post('/users/creators/quick-create', payload)

    return response.data
  }

  static update = async (
    id: string,
    payload: CreatorUserRequest
  ): Promise<CreatorUserResponse> => {
    const response = await client.put<CreatorUserResponse>(
      `/users/creators/${id}`,
      payload
    )

    return response.data
  }

  static delete = async (id: string): Promise<void> => {
    await client.delete(`/users/creators/${id}`)
  }

  static deleteAll = async (ids: string[]): Promise<void> => {
    await Promise.all(ids.map((id) => client.delete(`/users/creators/${id}`)))
  }

  static get = async (id: string): Promise<CreatorUserResponse> => {
    const response = await client.get<CreatorUserResponse>(
      `/users/creators/${id}`
    )

    return response.data
  }

  static findAll = async (
    pageable: Pageable,
    sortable: Sortable,
    filter: CreatorUserFilter
  ): Promise<PagedResponse<CreatorUserResponse>> => {
    const response = await client.get<PagedResponse<CreatorUserResponse>>(
      '/users/creators',
      { params: { ...pageable, ...sortable, ...filter } }
    )

    return response.data
  }
}
