import Joi from 'joi'
import { Locale } from './CurrencyCore'

export type CurrencyFilter = {
  code?: string
  enabled?: boolean
  locales?: string
  exchangeRate?: number
}

export type CurrencyRequest = {
  code: string
  enabled: boolean
  locales: Array<Locale>
  exchangeRate: number
}

export const CurrencyRequestSchema = Joi.object<CurrencyRequest>({
  code: Joi.string().required(),
  enabled: Joi.boolean().required(),
  locales: Joi.array().required(),
  exchangeRate: Joi.number().required(),
})

export type CurrencyResponse = {
  id?: string
  code?: string
  enabled?: boolean
  locales?: Array<Locale>
  exchangeRate?: number
  createdAt?: Date
  updatedAt?: Date
}
