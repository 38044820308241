import OccasionApi from 'api/occasions/OccasionApi'
import {
  OccasionRequest,
  OccasionRequestSchema,
  OccasionResponse,
} from 'api/occasions/OccasionPayloads'
import { AxiosError } from 'axios'
import useYelaForm from 'hooks/useYelaForm'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import 'twin.macro'
import ListView from 'views/common/list_view/ListView'
import OccasionDetail from './OccasionDetail'

const OccasionsList = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const dataTable = useRef<DataTable>(null)
  const [editItem, setEditItem] = useState<OccasionResponse>()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const form = useYelaForm<OccasionRequest>(OccasionRequestSchema)

  return (
    <>
      <Toast ref={toast} />

      <ListView<OccasionResponse, OccasionRequest>
        dataTable={dataTable}
        viewName="Occasions"
        dataKey="id"
        columns={[
          {
            display: 'Order',
            field: 'order',
            sortKey: 'order',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Slug',
            field: 'slug',
            sortKey: 'slug',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Name',
            field: 'name.en',
            sortKey: 'name',
            sortable: true,
            filterable: true,
          },
        ]}
        defaultSort={{ sortBy: 'order', desc: false }}
        onFetch={OccasionApi.findAll}
        form={form}
        dialogContent={(id?: string) => <OccasionDetail id={id} form={form} />}
        onNew={async () => {
          setEditItem(undefined)
          setShowDialog(true)
        }}
        onDetails={async (item: OccasionResponse) => {
          setEditItem(item)
          setShowDialog(true)
        }}
        onUpdate={async (id: string) => {
          await OccasionApi.update(id, form.getValues())
        }}
        onCreate={async () => {
          await OccasionApi.create(form.getValues())
        }}
        onDelete={async (items: OccasionResponse[]) => {
          await OccasionApi.deleteAll(items.map((i) => i.id as string)).catch(
            (error: AxiosError) => {
              toast.current?.show({
                severity: 'error',
                summary: `Status: ${error.response?.status}`,
                detail: error.response?.data?.message,
                life: 3000,
              })
            }
          )
        }}
      />
    </>
  )
}

export default OccasionsList
