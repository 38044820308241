/* eslint-disable react/display-name */
import CreatorUserApi from 'api/users/creator/CreatorUserApi'
import {
  CreatorUserRequest,
  CreatorUserRequestSchema,
  CreatorUserResponse,
} from 'api/users/creator/CreatorUserPayloads'
import { AxiosError } from 'axios'
import useYelaForm from 'hooks/useYelaForm'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/components/dialog/Dialog'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import 'twin.macro'
import { BooleanColumnTemplate } from 'views/common/list_view/columns/ColumnTemplates'
import { BooleanFilterTemplate } from 'views/common/list_view/filters/BooleanFilter'
import ListView from 'views/common/list_view/ListView'
import ContactDetail from './CreatorUserContactDetail'
import CreatorUserDetail from './CreatorUserDetail'

const CreatorUserList = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const dataTable = useRef<DataTable>(null)
  const [, setEditItem] = useState<CreatorUserResponse>()
  const [, setShowDialog] = useState<boolean>(false)
  const form = useYelaForm<CreatorUserRequest>(CreatorUserRequestSchema)
  const [contactsItem, setContactsItem] = useState<
    CreatorUserResponse | undefined
  >(undefined)
  const [showContactsDialog, setShowContactsDialog] = useState<boolean>(false)

  return (
    <>
      <Toast ref={toast} />

      <Dialog
        header={`${contactsItem?.name} - Contacts`}
        visible={showContactsDialog}
        style={{ minWidth: '768px' }}
        onHide={() => {
          setContactsItem(undefined)
          setShowContactsDialog(false)
        }}
        modal
        closeOnEscape={false}
        tw="max-w-full w-1/2"
      >
        {contactsItem?.id && <ContactDetail id={contactsItem?.id} />}
      </Dialog>

      <ListView<CreatorUserResponse, CreatorUserRequest>
        dataTable={dataTable}
        viewName="User - Creators"
        dataKey="id"
        columns={[
          {
            display: 'Photo',
            field: 'photo',
            sortKey: 'photo',
            sortable: false,
            filterable: false,
            body: (rowData: CreatorUserResponse) => {
              return (
                <img
                  src={`${rowData.photo}?tr=r-max,w-100`}
                  style={{
                    borderRadius: '50%',
                    width: 48,
                    height: 48,
                    objectFit: 'cover',
                  }}
                />
              )
            },
          },
          {
            display: 'Name',
            field: 'name',
            sortKey: 'name',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Phone',
            field: 'phone',
            sortKey: 'phone',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Enabled',
            field: 'enabled',
            sortKey: 'enabled',
            sortable: true,
            filterable: true,
            body: (rowData: CreatorUserResponse) => {
              return BooleanColumnTemplate(rowData?.enabled as boolean)
            },
            filterBody: BooleanFilterTemplate({
              field: 'enabled',
              dataTable: dataTable,
            }),
          },
          {
            display: 'Registered',
            field: 'registered',
            sortKey: 'registered',
            sortable: true,
            filterable: true,
            body: (rowData: CreatorUserResponse) => {
              return BooleanColumnTemplate(rowData?.registered as boolean)
            },
            filterBody: BooleanFilterTemplate({
              field: 'registered',
              dataTable: dataTable,
            }),
          },
          {
            display: 'Verified',
            field: 'verified',
            sortKey: 'verified',
            sortable: true,
            filterable: false,
            body: (rowData: CreatorUserResponse) => {
              return BooleanColumnTemplate(rowData?.verified as boolean)
            },
          },
          {
            display: 'Language',
            field: 'language',
            sortKey: 'language',
            sortable: false,
            filterable: false,
          },
        ]}
        defaultSort={{ sortBy: 'name', desc: false }}
        onFetch={CreatorUserApi.findAll}
        form={form}
        dialogContent={(id?: string) => (
          <CreatorUserDetail id={id} form={form} />
        )}
        onNew={async () => {
          setEditItem(undefined)
          setShowDialog(true)
        }}
        onDetails={async (item: CreatorUserResponse) => {
          setEditItem(item)
          setShowDialog(true)
        }}
        onUpdate={async (id: string) => {
          await CreatorUserApi.update(id, form.getValues())
        }}
        onCreate={async () => {
          await CreatorUserApi.create(form.getValues())
        }}
        onDelete={async (items: CreatorUserResponse[]) => {
          await CreatorUserApi.deleteAll(
            items.map((i) => i.id as string)
          ).catch((error: AxiosError) => {
            toast.current?.show({
              severity: 'error',
              summary: `Status: ${error.response?.status}`,
              detail: error.response?.data?.message,
              life: 3000,
            })
          })
        }}
        extraAction={(item) => (
          <Button
            icon="pi pi-phone"
            className="p-button-help p-button-rounded"
            onClick={() => {
              setContactsItem(item)
              setShowContactsDialog(true)
            }}
          />
        )}
      />
    </>
  )
}

export default CreatorUserList
