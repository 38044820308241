import { Translated } from 'api/core/Translated'
import Joi from 'joi'

export type CreatorUserFilter = {
  name?: string
  phone?: string
}

export type CreatorUserRequest = {
  name: string
  creatorId: string
  phone: string
  photo: string
  language: string
  enabled: boolean
  verified: boolean
  registered: boolean
}

export const CreatorUserRequestSchema = Joi.object<CreatorUserRequest>({
  name: Joi.string().min(2).required(),
  creatorId: Joi.string().required(),
  phone: Joi.string().allow(''),
  photo: Joi.string(),
  language: Joi.string(),
  enabled: Joi.boolean(),
  verified: Joi.boolean(),
  registered: Joi.boolean(),
})

export type CreatorUserResponse = {
  id?: string
  name?: string
  photo?: string
  phone?: string
  language?: string
  creatorId?: string
  enabled?: boolean
  verified?: boolean
  registered?: boolean
  createdAt?: Date
  updatedAt?: Date
}

export type QuickCreateRequest = {
  name: Translated
  surname: Translated
  phone: string
  videoPrice: number
  creatorId?: string
}

export const QuickCreateRequestSchema = Joi.object<QuickCreateRequest>({
  name: Joi.object<Translated>().required(),
  surname: Joi.object<Translated>().required(),
  phone: Joi.string().required(),
  videoPrice: Joi.number().required(),
  creatorId: Joi.string().optional(),
})
