import { Dropdown, DropdownChangeParams } from 'primereact/dropdown'
import React, { useState } from 'react'
import {
  TOrderMessageTypeFilter,
  TOptions,
} from './OrderMessageTypeFilter.types'

const OrderMessageTypeFilterTemplate = (
  props: TOrderMessageTypeFilter
): React.ReactNode => {
  const [messageType, setMessageType] = useState(null)
  const onMessageTypeChange = (e: DropdownChangeParams) => {
    props.dataTable.current?.filter(e.value, 'messageType', 'equals')
    setMessageType(e.value)
  }
  return (
    <Dropdown
      value={messageType}
      options={[
        { name: 'ALL', value: '' } as TOptions,
        { name: 'AUDIO', value: 'AUDIO' } as TOptions,
        { name: 'VIDEO', value: 'VIDEO' } as TOptions,
      ]}
      optionLabel="name"
      optionValue="value"
      onChange={onMessageTypeChange}
      className="p-column-filter"
    />
  )
}

export { OrderMessageTypeFilterTemplate }
