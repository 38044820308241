import { Global } from '@emotion/react'
import App from 'App'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/arya-orange/theme.css'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import 'tailwindcss/dist/base.min.css'
import { css, GlobalStyles as BaseStyle } from 'twin.macro'

const GlobalStyle = css`
  html,
  #root {
    height: 100vh;
    font-size: 14px;
    scroll-behavior: smooth;
    color: var(--text-color);
    background-color: var(--surface-a);
  }
`

const GlobalStyles = () => {
  return (
    <>
      <BaseStyle />
      <Global styles={GlobalStyle} />
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <Suspense fallback={<div>Loading... </div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)
