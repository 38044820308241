import { client } from 'api/api.root'
import { Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import {
  PromotionFilter,
  PromotionRequest,
  PromotionResponse,
} from './PromotionPayloads'

export default class PromotionApi {
  static create = async (
    payload: PromotionRequest
  ): Promise<PromotionResponse> => {
    const response = await client.post<PromotionResponse>(
      '/promotions',
      payload
    )

    return response.data
  }

  static update = async (
    id: string,
    payload: PromotionRequest
  ): Promise<PromotionResponse> => {
    const response = await client.put<PromotionResponse>(
      `/promotions/${id}`,
      payload
    )

    return response.data
  }

  static delete = async (id: string): Promise<void> => {
    await client.delete(`/promotions/${id}`)
  }

  static deleteAll = async (ids: string[]): Promise<void> => {
    await Promise.all(ids.map((id) => client.delete(`/promotions/${id}`)))
  }

  static get = async (id: string): Promise<PromotionResponse> => {
    const response = await client.get<PromotionResponse>(`/promotions/${id}`)

    return response.data
  }

  static findAll = async (
    pageable: Pageable,
    sortable: Sortable,
    filter: PromotionFilter
  ): Promise<PagedResponse<PromotionResponse>> => {
    const response = await client.get<PagedResponse<PromotionResponse>>(
      '/promotions',
      { params: { ...pageable, ...sortable, ...filter } }
    )

    return response.data
  }
}
