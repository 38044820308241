/* eslint-disable no-case-declarations */
import OrderApi from 'api/orders/OrderApi'
import {
  OccasionInfo,
  OrderDetailResponse,
  OrderRequest,
  OrderStatuses,
  RecipientInfo,
} from 'api/orders/OrderPayloads'
import { AxiosError } from 'axios'
import FormElement from 'components/form/element/FormElement'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Divider } from 'primereact/divider'
import { Toast } from 'primereact/toast'
import { TabView, TabPanel } from 'primereact/tabview'
import { FileUpload, FileUploadHandlerParam } from 'primereact/fileupload'
import { SplitButton } from 'primereact/splitbutton'
import React, { useEffect, useRef, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import tw from 'twin.macro'
import { Dialog } from 'primereact/components/dialog/Dialog'
import { BooleanColumnTemplate } from 'views/common/list_view/columns/ColumnTemplates'
import axios from 'axios'
import { OccasionResponse } from 'api/occasions/OccasionPayloads'
import OccasionApi from 'api/occasions/OccasionApi'
import { InputNumber } from 'primereact/inputnumber'

const Container = tw.div`
  px-4
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

const StatusText = tw.span`
  text-xl
`

const Table = tw.table`
  table-auto content-center text-center
`

const TableHeader = tw.th`
  border-solid border-2 border-white
`

type TOccasionDetail = {
  id?: string
  form: UseFormReturn<OrderRequest>
}

const OrderDetail = ({
  id,
  form: {
    register,
    setValue,
    formState: { errors },
  },
}: TOccasionDetail): JSX.Element => {
  const toast = useRef<Toast>(null)
  const [item, setItem] = useState<OrderDetailResponse>()
  const [loading, setLoading] = useState<boolean>()
  const [displayNotes, setDisplayNotes] = useState(false)
  const [extendDialog, setExtendDialog] = useState(false)
  const [extendHours, setExtendHours] = useState(0)
  const [actionNote, setActionNote] = useState('')
  const [actionType, setActionType] = useState('')
  const [displayPlayer, setDisplayPlayer] = useState(false)
  const [videoUrl, setVideoUrl] = useState<string | undefined>()
  const [occasions, setOccasions] = useState<OccasionResponse[]>()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const response = await OccasionApi.findAll(
        {
          page: 0,
          pageSize: 1000,
        },
        {
          desc: false,
          sortBy: 'name',
        },
        {}
      )
      if (response) {
        setOccasions(response.items)
      }
    })()
  }, [])
  useEffect(() => {
    if (!id) return
    ;(async () => {
      setLoading(true)
      const response = await OrderApi.get(id).catch((error) => {
        console.log(error) //TODO
        setLoading(false)
      })

      if (response) {
        setItem(response)
        setValue('buyerName', response.message?.buyerName as string)
        setValue('instruction', response.message?.instruction as string)
        setValue('language', response.message?.language as string)
        setValue('type', response.message?.messageType as string)
        setValue('occasion', response.message?.occasion as OccasionInfo)
        setValue('recipient', response.message?.recipient as RecipientInfo)
        setValue('recipient.type', response.message?.recipientType as string)
      }
      setLoading(false)
    })()
  }, [])

  const showSuccessToast = (message: string) => {
    toast.current?.show({
      severity: 'success',
      summary: message,
      life: 3000,
    })
  }

  const showErrorToast = (error: AxiosError) => {
    toast.current?.show({
      severity: 'error',
      summary: `Status: ${error.response?.status}`,
      detail: error.response?.data?.message,
      life: 5000,
    })
  }

  const closeNotesDialog = () => {
    setActionNote('')
    setActionType('')
    setDisplayNotes(false)
    setExtendDialog(false)
    setExtendHours(0)
  }

  const updateStatus = async () => {
    if (item?.id) {
      let result: void | OrderDetailResponse
      switch (actionType) {
        case 'extend':
          result = await OrderApi.extend(item?.id, {
            hours: extendHours,
            notes: actionNote,
          }).catch((error: AxiosError) => {
            showErrorToast(error)
          })
          if (result) {
            showSuccessToast('Order is extended successfully')
            setItem(result)
          }

          break
        case 'refund':
          result = await OrderApi.refund(item?.id, {
            notes: actionNote,
          }).catch((error: AxiosError) => {
            showErrorToast(error)
          })
          if (result) {
            showSuccessToast('Order is refunded successfully')
            setItem(result)
          }

          break
        case 'approveRequest':
          result = await OrderApi.approveRequest(item?.id, {
            notes: actionNote,
          }).catch((error: AxiosError) => {
            showErrorToast(error)
          })
          if (result) {
            showSuccessToast("Order's request is approved successfully")
            setItem(result)
          }

          break
        case 'approveResponse':
          result = await OrderApi.approveResponse(item?.id, {
            notes: actionNote,
          }).catch((error: AxiosError) => {
            showErrorToast(error)
          })
          if (result) {
            showSuccessToast("Order's response is approved successfully")
            setItem(result)
          }
          break
        case 'askForNewResponse':
          result = await OrderApi.declineResponse(item?.id, {
            notes: actionNote,
          }).catch((error: AxiosError) => {
            showErrorToast(error)
          })
          if (result) {
            showSuccessToast('Order is sent back to creator for new response')
            setItem(result)
          }
          break
        default:
          break
      }
      closeNotesDialog()
    }
  }

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => {
            closeNotesDialog()
          }}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          autoFocus
          onClick={async () => await updateStatus()}
        />
      </div>
    )
  }

  const promote = async (uploadId: string) => {
    const result = await OrderApi.promoteToSend(
      item?.id as string,
      uploadId
    ).catch((error: AxiosError) => {
      showErrorToast(error)
    })
    if (result) {
      showSuccessToast('Promote to send action is completed successfully')
      setItem(result)
    }
  }

  const openPlayer = async (uploadId: string, original: boolean) => {
    const response = await OrderApi.getWatchUrl(
      item?.id as string,
      uploadId,
      original
    ).catch((error: AxiosError) => {
      showErrorToast(error)
      return
    })
    if (response) {
      setVideoUrl(response.url)
      setDisplayPlayer(true)
    }
  }

  const handleManualFileUpload = async (event: FileUploadHandlerParam) => {
    const uploadUrlResponse = await OrderApi.getUploadUrl(
      item?.id as string
    ).catch((error: AxiosError) => {
      showErrorToast(error)
    })

    if (uploadUrlResponse) {
      const file = event.files[0]
      const options = {
        headers: {
          'Content-Type': file.type,
        },
      }

      const uploadResult = await axios.put(uploadUrlResponse.url, file, options)
      if (uploadResult && uploadResult.status == 200) {
        const uploadEndResult = await OrderApi.uploadEnd(
          item?.id as string,
          uploadUrlResponse.id
        )

        if (uploadEndResult) {
          showSuccessToast('File upload is completed successfully')
          setItem(uploadEndResult)
        }
      }
    }
  }

  return (
    <Container>
      <Dialog
        header="Notes"
        visible={displayNotes}
        style={{ width: '300px' }}
        footer={renderFooter}
        onHide={closeNotesDialog}
      >
        <InputTextarea
          id="notes"
          rows={5}
          style={{ width: '100%' }}
          dir="auto"
          onChange={(e) => setActionNote(e.target.value)}
        />
      </Dialog>
      <Dialog
        header="Extend by Hours"
        visible={extendDialog}
        style={{ width: '300px' }}
        footer={renderFooter}
        onHide={closeNotesDialog}
      >
        <InputNumber
          id="hours"
          value={0}
          showButtons
          buttonLayout="horizontal"
          step={1}
          style={{ width: '100%' }}
          onChange={(e) => setExtendHours(e.value)}
          decrementButtonClassName="p-button-danger"
          incrementButtonClassName="p-button-success"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
        />

        <Divider />

        <InputTextarea
          id="notes"
          rows={5}
          style={{ width: '100%' }}
          dir="auto"
          onChange={(e) => setActionNote(e.target.value)}
        />
      </Dialog>
      <Dialog
        header={`Message from ${item?.creator?.name} to ${item?.message?.recipient?.name}`}
        visible={displayPlayer}
        onHide={() => {
          setDisplayPlayer(false)
        }}
      >
        <video style={{ width: '100%' }} controls>
          <source src={videoUrl}></source>
          Your browser does not support the video tag.
        </video>
      </Dialog>
      <Toast ref={toast} />
      <Flex>
        <Flex1>
          <StatusText>Current Status: {item?.status}</StatusText>
        </Flex1>
      </Flex>
      <Divider />
      <Flex>
        <Flex1>
          <span className="p-buttonset">
            <Button
              icon="pi pi-clock"
              label="Extend"
              className="p-button-danger p-button-sm"
              loading={loading}
              disabled={
                item?.status == OrderStatuses.ResponseApproved ||
                item?.status == OrderStatuses.Completed ||
                item?.status == OrderStatuses.Refunded
              }
              onClick={async () => {
                if (item?.id) {
                  setActionType('extend')
                  setExtendDialog(true)
                }
              }}
            />
            <Button
              icon="pi pi-credit-card"
              label="Refund"
              className="p-button-help p-button-sm"
              loading={loading}
              disabled={
                item?.status == OrderStatuses.ResponseApproved ||
                item?.status == OrderStatuses.Completed ||
                item?.status == OrderStatuses.Refunded
              }
              onClick={async () => {
                if (item?.id) {
                  setActionType('refund')
                  setDisplayNotes(true)
                }
              }}
            />
            <Button
              icon="pi pi-thumbs-up"
              label="Approve Request"
              className="p-button-primary p-button-sm"
              loading={loading}
              disabled={item?.status != OrderStatuses.WaitingRequestReview}
              onClick={async () => {
                if (item?.id) {
                  setActionType('approveRequest')
                  setDisplayNotes(true)
                }
              }}
            />
            <Button
              icon="pi pi-play"
              label="Approve Response"
              className="p-button-secondary p-button-sm"
              loading={loading}
              disabled={item?.status != OrderStatuses.WaitingResponseReview}
              onClick={async () => {
                if (item?.id) {
                  setActionType('approveResponse')
                  setDisplayNotes(true)
                }
              }}
            />
            <Button
              icon="pi pi-replay"
              label="Ask for new Response"
              className="p-button-info p-button-sm"
              loading={loading}
              disabled={item?.status != OrderStatuses.WaitingResponseReview}
              onClick={async () => {
                if (item?.id) {
                  setActionType('askForNewResponse')
                  setDisplayNotes(true)
                }
              }}
            />
          </span>
        </Flex1>
      </Flex>
      <Divider />
      <TabView>
        <TabPanel header="Order Details">
          <Flex>
            <Flex1>
              <FormElement id="creatorName" labelText="Creator Name">
                <InputText
                  id="creatorName"
                  defaultValue={item?.creator?.name}
                  disabled
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="buyerName"
                labelText="Buyer Name"
                errorText={errors.buyerName?.message}
              >
                <InputText id="buyerName" {...register('buyerName')} />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement
                id="instruction"
                labelText="Instruction"
                errorText={errors.instruction?.message}
              >
                <InputTextarea
                  dir="auto"
                  rows={5}
                  id="instruction"
                  {...register('instruction')}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement id="language" labelText="Language">
                <Dropdown
                  id="language"
                  {...register('language')}
                  value={item?.message?.language}
                  options={[
                    {
                      code: 'en',
                    },
                    {
                      code: 'ar',
                    },
                  ]}
                  optionLabel="code"
                  optionValue="code"
                  onChange={(e) => {
                    setItem({
                      ...item,
                      message: {
                        buyerName: item?.message?.buyerName,
                        instruction: item?.message?.instruction,
                        language: e.value,
                        messageType: item?.message?.messageType,
                        occasion: item?.message?.occasion,
                        recipient: item?.message?.recipient,
                        recipientType: item?.message?.recipientType,
                      },
                    })
                    setValue('language', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="type" labelText="Message Type">
                <Dropdown
                  id="type"
                  {...register('type')}
                  value={item?.message?.messageType}
                  options={[
                    {
                      code: 'video',
                    },
                    {
                      code: 'audio',
                    },
                  ]}
                  optionLabel="code"
                  optionValue="code"
                  onChange={(e) => {
                    setItem({
                      ...item,
                      message: {
                        buyerName: item?.message?.buyerName,
                        instruction: item?.message?.instruction,
                        language: item?.message?.language,
                        messageType: e.value,
                        occasion: item?.message?.occasion,
                        recipient: item?.message?.recipient,
                        recipientType: item?.message?.recipientType,
                      },
                    })
                    setValue('type', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="occasion" labelText="Occasion">
                <Dropdown
                  id="occasion"
                  {...register('occasion')}
                  value={item?.message?.occasion?.slug}
                  options={occasions}
                  optionLabel="name.en"
                  optionValue="slug"
                  onChange={(e) => {
                    const selectedOccasion = occasions?.filter(
                      (t) => t.slug == e.value
                    )[0]
                    if (selectedOccasion) {
                      setItem({
                        ...item,
                        message: {
                          buyerName: item?.message?.buyerName,
                          instruction: item?.message?.instruction,
                          language: item?.message?.language,
                          messageType: item?.message?.messageType,
                          occasion: {
                            name: selectedOccasion?.name?.en as string,
                            slug: selectedOccasion?.slug as string,
                          },
                          recipient: item?.message?.recipient,
                          recipientType: item?.message?.recipientType,
                        },
                      })
                      setValue('occasion', {
                        name: selectedOccasion?.name?.en as string,
                        slug: selectedOccasion?.slug as string,
                      })
                    }
                  }}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement
                id="recipientName"
                labelText="Recipient Name"
                errorText={errors.recipient?.name?.message}
              >
                <InputText
                  id="recipientName"
                  dir="auto"
                  {...register('recipient.name')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="recipientEmail"
                labelText="Recipient Email"
                errorText={errors.recipient?.email?.message}
              >
                <InputText
                  id="recipientEmail"
                  dir="auto"
                  {...register('recipient.email')}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="recipientPhone"
                labelText="Recipient Phone"
                errorText={errors.recipient?.phone?.message}
              >
                <InputText
                  id="recipientPhone"
                  dir="auto"
                  {...register('recipient.phone')}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement id="recipientType" labelText="Type">
                <Dropdown
                  id="recipientType"
                  {...register('recipient.type')}
                  value={item?.message?.recipientType}
                  options={[
                    {
                      code: 'gift',
                    },
                    {
                      code: 'myself',
                    },
                  ]}
                  optionLabel="code"
                  optionValue="code"
                  onChange={(e) => {
                    setItem({
                      ...item,
                      message: {
                        buyerName: item?.message?.buyerName,
                        instruction: item?.message?.instruction,
                        language: item?.message?.language,
                        messageType: item?.message?.messageType,
                        occasion: item?.message?.occasion,
                        recipient: item?.message?.recipient,
                        recipientType: e.value,
                      },
                    })
                    setValue('recipient.type', e.value)
                  }}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement
                id="notes"
                labelText="Notes"
                errorText={errors.notes?.message}
              >
                <InputTextarea
                  id="notes"
                  {...register('notes')}
                  rows={5}
                  dir="auto"
                />
              </FormElement>
            </Flex1>
          </Flex>
        </TabPanel>
        <TabPanel header="Uploads">
          <Flex>
            <Flex1>
              <FileUpload
                multiple={false}
                name="manualUploadFile"
                url="./upload"
                customUpload
                uploadHandler={handleManualFileUpload}
                accept=".mp4,.aac"
              />
            </Flex1>
          </Flex>
          <Divider />
          <Table style={{ width: '100%' }}>
            <thead>
              <tr>
                <TableHeader>Uploaded By</TableHeader>
                <TableHeader>Is Manual Upload</TableHeader>
                <TableHeader>Promoted to Send</TableHeader>
                <TableHeader>Uploaded At</TableHeader>
                <TableHeader>Actions</TableHeader>
              </tr>
            </thead>
            <tbody>
              {item &&
                item?.uploads &&
                item?.uploads?.length > 0 &&
                item?.uploads?.map((upload) => {
                  return (
                    <tr key={upload.id} style={{ border: '1px solid white' }}>
                      <td>{upload.uploadedBy ?? '-'}</td>
                      <td>{BooleanColumnTemplate(upload.isManualUpload)}</td>
                      <td>{BooleanColumnTemplate(upload.promotedToSend)}</td>
                      <td>{upload.uploadedAt}</td>
                      <td>
                        <SplitButton
                          label="Watch"
                          model={[
                            {
                              label: 'Watch Original',
                              icon: 'pi pi-play',
                              command: () => {
                                openPlayer(upload?.id, true)
                              },
                            },
                            {
                              label: 'Watch Processed',
                              icon: 'pi pi-palette',
                              command: () => {
                                openPlayer(upload?.id, false)
                              },
                            },
                          ]}
                          className="p-button-secondary p-button-sm"
                        ></SplitButton>
                        <Button
                          icon="pi pi-chevron-circle-up"
                          style={{ marginLeft: 5 }}
                          className="p-button-success p-button-sm"
                          label="Promote"
                          onClick={() => promote(upload.id)}
                        ></Button>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </TabPanel>
      </TabView>
    </Container>
  )
}

export default OrderDetail
