import { InterpolationWithTheme } from '@emotion/core'
import { Filterable, Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import { Button } from 'primereact/button'
import { ColumnFilterParams } from 'primereact/components/column/Column'
import { DataTable, DataTableSortParams } from 'primereact/datatable'
import { RefObject } from 'react'
import { ReactNode } from 'react'
import { UseFormReturn } from 'react-hook-form'

export const DefaultPage = {
  first: 0,
  page: 0,
  pageCount: 1,
  rows: 10,
}

export const DefaultSort = (sort: Sortable): DataTableSortParams => {
  return {
    sortField: sort.sortBy,
    sortOrder: sort.desc ? -1 : 1,
    multiSortMeta: undefined,
  }
}

export type ColumProps<E> = {
  display: string
  field: string
  filterField?: string
  sortKey?: string
  sortable: boolean
  filterable: boolean
  body?: ReactNode
  filterBody?: ReactNode
  style?: any
  filterFunction?: (
    value: any,
    filter: any,
    filterLocale: string,
    params: ColumnFilterParams
  ) => void
}

export type ListViewProps<E, R> = {
  dataTable: RefObject<DataTable>
  viewName: string
  dataKey: keyof E
  columns: ColumProps<E>[]
  defaultSort: Sortable
  form?: UseFormReturn<R>
  maximized?: boolean
  dialogContent?: (id?: string) => ReactNode
  onFetch: (
    page: Pageable,
    sort: Sortable,
    filter: Filterable
  ) => Promise<PagedResponse<E>>
  onNew?: () => Promise<void>
  onCreate?: () => Promise<void>
  onUpdate?: (id: string) => Promise<void>
  onDetails?: (item: E) => Promise<void>
  onDelete?: (items: E[]) => Promise<void>
  extraAction?: (item: E) => JSX.Element
  showDialogAfterOperation?: boolean
}
