import CreatorUserContactsApi from 'api/users/creator/CreatorUserContactsApi'
import {
  CreatorUserContactResponse,
  CreatorUserContactRequestSchema,
  CreatorUserContactRequest,
} from 'api/users/creator/CreatorUserContactsPayloads'
import { AxiosError } from 'axios'
import FormElement from 'components/form/element/FormElement'
import useYelaForm from 'hooks/useYelaForm'
import { Button } from 'primereact/button'
import { Column } from 'primereact/components/column/Column'
import { confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'

const CreateSection = ({
  id,
  onComplete,
}: {
  id: string
  onComplete: () => void
}): JSX.Element => {
  const toast = useRef<Toast>(null)

  const form = useYelaForm<CreatorUserContactRequest>(
    CreatorUserContactRequestSchema
  )
  const { isDirty, isValid } = form.formState

  const onCreate = () => {
    CreatorUserContactsApi.create(id, form.getValues())
      .then(() => {
        form.setValue('type', '')
        form.setValue('name', '')
        form.setValue('phone', '')
        toast.current?.show({
          severity: 'success',
          detail: `Created successfully`,
          sticky: true,
        })
        onComplete()
      })
      .catch((error: AxiosError) => {
        console.log(error.response?.data?.errors)
        toast.current?.show({
          severity: 'error',
          detail: `${
            error.response?.data?.message
          } [ ${error.response?.data?.errors?.join(' ')} ]`,
          sticky: true,
        })
      })
  }

  return (
    <>
      <Toast ref={toast} />

      <div css={tw`flex flex-row space-x-4 items-center`}>
        <div css={tw`flex flex-1 space-x-4`}>
          <FormElement
            id="type"
            labelText="Type"
            errorText={form.formState.errors.type?.message}
          >
            <InputText
              id="type"
              defaultValue=""
              placeholder="Assistant"
              {...form.register('type')}
            />
          </FormElement>

          <FormElement id="name" labelText="Name" errorText={''}>
            <InputText
              id="name"
              defaultValue=""
              placeholder="Kaan Basal"
              {...form.register('name')}
            />
          </FormElement>

          <FormElement id="phone" labelText="Phone" errorText={''}>
            <InputText
              id="phone"
              defaultValue=""
              placeholder="+905551112233"
              {...form.register('phone')}
            />
          </FormElement>
        </div>

        <Button
          icon="pi pi-plus"
          className="p-button-primary"
          label="New"
          css={tw`h-12 mb-6`}
          onClick={async () => {
            await form.trigger()

            if (!isDirty || !isValid) return

            onCreate()
          }}
        />
      </div>
    </>
  )
}

const ListSection = ({
  id,
  contacts,
  onComplete,
}: {
  id: string
  contacts: CreatorUserContactResponse[]
  onComplete: () => void
}): JSX.Element => {
  const toast = useRef<Toast>(null)

  const onDelete = (id: string, cid: string) => {
    CreatorUserContactsApi.delete(id, cid)
      .then(() => {
        toast.current?.show({
          severity: 'success',
          detail: `Deleted successfully`,
          sticky: true,
        })
        onComplete()
      })
      .catch((error: AxiosError) => {
        console.log(error.response?.data?.errors)
        toast.current?.show({
          severity: 'error',
          detail: `${
            error.response?.data?.message
          } [ ${error.response?.data?.errors?.join(' ')} ]`,
          sticky: true,
        })
      })
  }

  const ActionColumn = (item: CreatorUserContactResponse): JSX.Element => {
    return (
      <div css={tw`flex space-x-2 justify-center`}>
        <Button
          icon="pi pi-trash"
          className="p-button-danger p-button-rounded"
          onClick={() => {
            confirmDialog({
              message: `Are you sure you want to delete ${item.type} -> ${item.phone}`,
              header: 'Delete Confirmation',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: async () => {
                await onDelete(id, item.id!)
              },
            })
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Toast ref={toast} />

      <DataTable value={contacts}>
        <Column field="type" header="Type"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="phone" header="Phone"></Column>
        <Column body={ActionColumn} />
      </DataTable>
    </>
  )
}

const ContactDetail = ({ id }: { id: string }): JSX.Element => {
  const [contacts, setContacts] = useState<CreatorUserContactResponse[]>([])

  const fetch = () => {
    CreatorUserContactsApi.findAll(id).then((r) => setContacts(r.items))
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <>
      <CreateSection id={id} onComplete={fetch} />
      <Divider
        align="center"
        layout="horizontal"
        type="dashed"
        css={tw`h-1 bg-gray-500`}
      />
      <ListSection id={id} contacts={contacts} onComplete={fetch} />
    </>
  )
}

export default ContactDetail
