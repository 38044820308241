import { client } from 'api/api.root'
import { Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import {
  CreatorUserContactRequest,
  CreatorUserContactResponse,
} from './CreatorUserContactsPayloads'
import {
  CreatorUserFilter,
  CreatorUserRequest,
  CreatorUserResponse,
  QuickCreateRequest,
} from './CreatorUserPayloads'

export default class CreatorUserContactsApi {
  static create = async (
    id: string,
    payload: CreatorUserContactRequest
  ): Promise<CreatorUserContactResponse> => {
    const response = await client.post<CreatorUserContactResponse>(
      `/users/creators/${id}/contacts`,
      payload
    )

    return response.data
  }

  static delete = async (id: string, cid: string): Promise<void> => {
    await client.delete(`/users/creators/${id}/contacts/${cid}`)
  }

  static get = async (
    id: string,
    cid: string
  ): Promise<CreatorUserContactResponse> => {
    const response = await client.get<CreatorUserContactResponse>(
      `/users/creators/${id}/contacts/${cid}`
    )

    return response.data
  }

  static findAll = async (
    id: string
  ): Promise<PagedResponse<CreatorUserContactResponse>> => {
    const response = await client.get<
      PagedResponse<CreatorUserContactResponse>
    >(`/users/creators/${id}/contacts`, {})

    return response.data
  }
}
