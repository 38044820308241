import Joi from 'joi'

export type FanUserFilter = {
  name?: number
  email?: string
}

export type FanUserRequest = {
  email: string
  enabled: boolean
  name: string
  language: Language
  applyPromotion: boolean
}

export const FanUserRequestSchema = Joi.object<FanUserRequest>({
  email: Joi.string().required(),
  enabled: Joi.boolean().required(),
  applyPromotion: Joi.boolean(),
  language: Joi.string(),
  name: Joi.string(),
})

export type FanUserResponse = {
  id?: string
  name?: string
  email?: string
  enabled?: boolean
  applyPromotion?: boolean
  verified?: boolean
  registered?: boolean
  language?: Language
  createdAt?: Date
  updatedAt?: Date
}

export type Language = {
  code: string
}

export type FanUserBulkInsert = {
  email: string
  language: string
  applyPromotion: boolean
}
