import CreatorApi from 'api/creators/CreatorApi'
import { CreatorDetailResponse } from 'api/creators/CreatorPayloads'
import CreatorUserApi from 'api/users/creator/CreatorUserApi'
import {
  CreatorUserRequest,
  CreatorUserResponse,
} from 'api/users/creator/CreatorUserPayloads'
import FormElement from 'components/form/element/FormElement'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import tw from 'twin.macro'

const Container = tw.div`
  px-4
`

const Flex = tw.div`
  flex space-x-2 justify-center
`

const Flex1 = tw.div`
  flex-1
`

type TCreatorUserDetail = {
  id?: string
  form: UseFormReturn<CreatorUserRequest>
}

const CreatorUserDetail = ({
  id,
  form: {
    register,
    setValue,
    formState: { errors },
  },
}: TCreatorUserDetail): JSX.Element => {
  const [item, setItem] = useState<CreatorUserResponse>()
  const [creatorDetails, setCreatorDetails] =
    useState<CreatorDetailResponse[]>()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const response = await CreatorApi.findAllNotAssigned(item?.creatorId)
      if (response) {
        setCreatorDetails(response.items)
      }
    })()
  }, [item])

  useEffect(() => {
    if (!id) return
    ;(async () => {
      const response = await CreatorUserApi.get(id).catch((error) => {
        console.log(error) //TODO
      })

      if (response) {
        setItem(response)
        setValue('name', response.name as string)
        setValue('photo', response.photo as string)
        setValue('phone', response.phone as string)
        setValue('language', response.language as string)
        setValue('creatorId', response.creatorId as string)
        setValue('enabled', response.enabled as boolean)
        setValue('verified', response.verified as boolean)
        setValue('registered', response.registered as boolean)
      }
    })()
  }, [])

  return (
    <Container>
      {item && item.id && (
        <>
          <Flex>
            <Flex1>
              <FormElement id="id" labelText="ID">
                <InputText id="id" disabled defaultValue={item.id} />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="created-at" labelText="Created At">
                <Calendar
                  id="created-at"
                  disabled
                  showIcon
                  showTime
                  value={new Date(item.createdAt as Date)}
                />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="updated-at" labelText="Updated At">
                <Calendar
                  id="updated-at"
                  disabled
                  showIcon
                  showTime
                  value={item.updatedAt && new Date(item.updatedAt)}
                />
              </FormElement>
            </Flex1>
          </Flex>
          <Flex>
            <Flex1>
              <FormElement id="registered" labelText="Registered">
                <Checkbox checked={item?.registered} disabled />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="verified" labelText="Verified">
                <Checkbox checked={item?.verified} disabled />
              </FormElement>
            </Flex1>
            <Flex1>
              <FormElement id="enabled" labelText="Enabled">
                <Checkbox
                  onChange={(e) => {
                    setItem({ ...item, enabled: e.checked })
                    setValue('enabled', e.checked)
                  }}
                  checked={item?.enabled}
                />
              </FormElement>
            </Flex1>
          </Flex>
        </>
      )}
      <div>
        <FormElement
          id="language"
          labelText="Language"
          errorText={errors.language?.message}
        >
          <Dropdown
            id="language"
            {...register('language')}
            value={item?.language}
            options={['en', 'ar']}
            onChange={(e) => {
              setItem({ ...item, language: e.value })
              setValue('language', e.value)
            }}
          />
        </FormElement>
        <FormElement
          id="name"
          labelText="Name"
          errorText={errors.name?.message}
        >
          <InputText id="name" {...register('name')} />
        </FormElement>
        <FormElement id="creatorId" labelText="Creator Detail">
          <Dropdown
            id="creatorId"
            {...register('creatorId')}
            value={item?.creatorId}
            options={creatorDetails}
            optionLabel="slug"
            optionValue="id"
            onChange={(e) => {
              setItem({ ...item, creatorId: e.value })
              setValue('creatorId', e.value)
            }}
          />
        </FormElement>
        <FormElement
          id="phone"
          labelText="phone"
          errorText={errors.phone?.message}
        >
          <InputText
            id="phone"
            dir="auto"
            {...register('phone')}
            readOnly={item && item.id ? true : false}
          />
        </FormElement>

        <FormElement
          id="photo"
          labelText="photo"
          errorText={errors.photo?.message}
        >
          <InputText id="photo" dir="auto" {...register('photo')} />
        </FormElement>
      </div>
    </Container>
  )
}

export default CreatorUserDetail
