import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthStateManager } from 'states/AuthState'
import Layout from 'views/common/layout/Layout'

export type AuthorizedProps = {
  exact?: boolean
  path?: string
  permission: string
  component: () => JSX.Element
}

export function Authorized({
  exact,
  path,
  permission,
  component,
}: AuthorizedProps): JSX.Element {
  const checkPermission = (permission: string): boolean => {
    const authState = AuthStateManager.readState()
    //TODO
    return authState != null //&& authState.permissions.includes(permission)
  }

  return (
    <Route exact={exact} path={path}>
      {checkPermission(permission) ? (
        <Layout>{component()}</Layout>
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  )
}
