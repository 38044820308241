import {
  DeepPartial,
  UnpackNestedValue,
  useForm,
  UseFormReturn,
} from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { Schema } from 'joi'

const useYelaForm = <T>(
  schema: Schema,
  defaultValues: UnpackNestedValue<DeepPartial<T>> | undefined = undefined
): UseFormReturn<T> =>
  useForm<T>({
    mode: 'all',
    defaultValues: defaultValues,
    resolver: joiResolver(schema),
  })

export default useYelaForm
