import Joi from 'joi'

export type PromotionFilter = {
  email?: string
  amount?: number
  type?: string
  code?: string
  validAfter?: Date
  validUntil?: Date
}

export type PromotionRequest = {
  email: string
  amount: number
  type: string
  language: string
  validAfter: Date
  validUntil: Date
  creatorIds?: string[]
}

export const PromotionRequestSchema = Joi.object<PromotionRequest>({
  email: Joi.string().required(),
  amount: Joi.number().required(),
  type: Joi.string().required(),
  language: Joi.string(),
  validAfter: Joi.date().required(),
  validUntil: Joi.date().required(),
  creatorIds: Joi.array().items(Joi.string()).allow(null),
})

export type PromotionResponse = {
  id?: string
  email?: string
  amount?: number
  language?: string
  code?: string
  type?: string
  validAfter?: Date
  validUntil?: Date
  creatorIds?: string[]
}
