import { client } from 'api/api.root'
import { Pageable, Sortable } from 'api/core/request/Requests'
import { PagedResponse } from 'api/core/response/Responses'
import {
  WithNotePayload,
  OrderDetailResponse,
  OrderFilter,
  OrderRequest,
  OrderResponse,
  OrderUploadUrlResponse,
  OrderWatchUrlResponse,
  ExtendByHoursRequest,
} from './OrderPayloads'

export default class OrderApi {
  static update = async (
    id: string,
    payload: OrderRequest
  ): Promise<OrderDetailResponse> => {
    const response = await client.put<OrderDetailResponse>(
      `/orders/${id}`,
      payload
    )

    return response.data
  }

  static get = async (id: string): Promise<OrderDetailResponse> => {
    const response = await client.get<OrderDetailResponse>(`/orders/${id}`)

    return response.data
  }

  static findAll = async (
    pageable: Pageable,
    sortable: Sortable,
    filter: OrderFilter
  ): Promise<PagedResponse<OrderResponse>> => {
    const response = await client.get<PagedResponse<OrderResponse>>('/orders', {
      params: { ...pageable, ...sortable, ...filter },
    })

    return response.data
  }
  static approveRequest = async (
    id: string,
    payload: WithNotePayload
  ): Promise<OrderDetailResponse> => {
    const response = await client.post<OrderDetailResponse>(
      `/orders/${id}/request-approve`,
      payload
    )

    return response.data
  }

  static approveResponse = async (
    id: string,
    payload: WithNotePayload
  ): Promise<OrderDetailResponse> => {
    const response = await client.post<OrderDetailResponse>(
      `/orders/${id}/response-approve`,
      payload
    )

    return response.data
  }

  static declineResponse = async (
    id: string,
    payload: WithNotePayload
  ): Promise<OrderDetailResponse> => {
    const response = await client.post<OrderDetailResponse>(
      `/orders/${id}/response-decline`,
      payload
    )

    return response.data
  }

  static refund = async (
    id: string,
    payload: WithNotePayload
  ): Promise<OrderDetailResponse> => {
    const response = await client.post<OrderDetailResponse>(
      `/orders/${id}/refund`,
      payload
    )

    return response.data
  }

  static extend = async (
    id: string,
    payload: ExtendByHoursRequest
  ): Promise<OrderDetailResponse> => {
    const response = await client.post<OrderDetailResponse>(
      `/orders/${id}/extend`,
      payload
    )

    return response.data
  }

  static promoteToSend = async (
    id: string,
    uploadId: string
  ): Promise<OrderDetailResponse> => {
    const response = await client.post<OrderDetailResponse>(
      `/orders/${id}/response-upload/${uploadId}/promote`
    )

    return response.data
  }

  static getUploadUrl = async (id: string): Promise<OrderUploadUrlResponse> => {
    const response = await client.post<OrderUploadUrlResponse>(
      `/orders/${id}/response-upload`
    )

    return response.data
  }

  static uploadEnd = async (
    id: string,
    uploadId: string
  ): Promise<OrderDetailResponse> => {
    const response = await client.post<OrderDetailResponse>(
      `/orders/${id}/response-upload/${uploadId}`,
      {
        notes: '',
      }
    )

    return response.data
  }

  static getWatchUrl = async (
    id: string,
    uploadId: string,
    original: boolean
  ): Promise<OrderWatchUrlResponse> => {
    let endpoint = `/orders/${id}/response-upload/${uploadId}`
    if (original) endpoint += '?original=true'
    const response = await client.get<OrderWatchUrlResponse>(endpoint)
    return response.data
  }
}
