/* eslint-disable react/display-name */
import FanUserApi from 'api/users/fan/FanUserApi'
import {
  FanUserBulkInsert,
  FanUserRequest,
  FanUserRequestSchema,
  FanUserResponse,
} from 'api/users/fan/FanUserPayloads'
import { AxiosError } from 'axios'
import useYelaForm from 'hooks/useYelaForm'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import 'twin.macro'
import { BooleanColumnTemplate } from 'views/common/list_view/columns/ColumnTemplates'
import ListView from 'views/common/list_view/ListView'
import FanUserDetail from './FanUserDetail'
import XLSX from 'xlsx'
import { FileUpload, FileUploadSelectParams } from 'primereact/fileupload'
import { Divider } from 'primereact/divider'
import { BooleanFilterTemplate } from 'views/common/list_view/filters/BooleanFilter'

const FanUserList = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const uploader = useRef<FileUpload>(null)
  const dataTable = useRef<DataTable>(null)
  const [, setEditItem] = useState<FanUserResponse>()
  const [, setShowDialog] = useState<boolean>(false)
  const form = useYelaForm<FanUserRequest>(FanUserRequestSchema)
  const [file, setFile] = useState<Blob>()
  const handleChange = (e: FileUploadSelectParams) => {
    if (e.files && e.files[0]) setFile(e.files[0])
  }
  const handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const bstr = e.target?.result
      const wb = XLSX.read(bstr, {
        type: 'binary',
      })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json<FanUserBulkInsert>(ws)
      uploader.current?.clear()
      FanUserApi.bulkInsert(data)
        .then(() => {
          dataTable.current?.filter(null, 'name', 'custom')
        })
        .catch((error) => {
          toast.current?.show({
            severity: 'error',
            summary: `Status: ${error.response?.status} ${
              error.response?.data.errors
                ? error.response.data.errors.join(' ')
                : ''
            }`,
            detail: error.response?.data?.message,
            sticky: true,
          })
        })
    }
    if (file) reader.readAsBinaryString(file)
  }

  return (
    <>
      <Toast ref={toast} />
      <FileUpload
        ref={uploader}
        chooseLabel="Choose Excel File to Bulk Insert"
        onSelect={handleChange}
        accept=".xlsx,.xls"
        mode="basic"
        customUpload
        uploadHandler={handleFile}
      ></FileUpload>
      <Divider />
      <ListView<FanUserResponse, FanUserRequest>
        dataTable={dataTable}
        viewName="User - Fans"
        dataKey="id"
        columns={[
          {
            display: 'Name',
            field: 'name',
            sortKey: 'name',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Email',
            field: 'email',
            sortKey: 'email',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Enabled',
            field: 'enabled',
            sortKey: 'enabled',
            sortable: true,
            filterable: true,
            body: (rowData: FanUserResponse) => {
              return BooleanColumnTemplate(rowData?.enabled as boolean)
            },
            filterBody: BooleanFilterTemplate({
              field: 'enabled',
              dataTable: dataTable,
            }),
          },
          {
            display: 'Verified',
            field: 'verified',
            sortKey: 'verified',
            sortable: true,
            filterable: true,
            body: (rowData: FanUserResponse) => {
              return BooleanColumnTemplate(rowData?.verified as boolean)
            },
            filterBody: BooleanFilterTemplate({
              field: 'verified',
              dataTable: dataTable,
            }),
          },
          {
            display: 'Registered',
            field: 'registered',
            sortKey: 'registered',
            sortable: true,
            filterable: true,
            body: (rowData: FanUserResponse) => {
              return BooleanColumnTemplate(rowData?.registered as boolean)
            },
            filterBody: BooleanFilterTemplate({
              field: 'registered',
              dataTable: dataTable,
            }),
          },
          {
            display: 'Created At',
            field: 'createdAt',
            sortKey: 'createdAt',
            sortable: true,
            filterable: false,
          },
        ]}
        defaultSort={{ sortBy: 'createdAt', desc: true }}
        onFetch={FanUserApi.findAll}
        form={form}
        dialogContent={(id?: string) => <FanUserDetail id={id} form={form} />}
        onNew={async () => {
          setEditItem(undefined)
          setShowDialog(true)
        }}
        onDetails={async (item: FanUserResponse) => {
          setEditItem(item)
          setShowDialog(true)
        }}
        onUpdate={async (id: string) => {
          await FanUserApi.update(id, form.getValues())
        }}
        onCreate={async () => {
          await FanUserApi.create(form.getValues())
        }}
        onDelete={async (items: FanUserResponse[]) => {
          await FanUserApi.deleteAll(items.map((i) => i.id as string)).catch(
            (error: AxiosError) => {
              toast.current?.show({
                severity: 'error',
                summary: `Status: ${error.response?.status}`,
                detail: error.response?.data?.message,
                life: 3000,
              })
            }
          )
        }}
      />
    </>
  )
}

export default FanUserList
