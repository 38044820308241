import 'twin.macro'
import React, { useRef } from 'react'
import { HeaderWrapper } from './Header.styles'
import { Avatar } from 'primereact/avatar'
import { Badge } from 'primereact/badge'
import { AuthStateManager } from 'states/AuthState'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Button } from 'primereact/button'
import { useHistory } from 'react-router-dom'

const Header = (): JSX.Element => {
  const overlay = useRef<OverlayPanel>(null)
  const state = AuthStateManager.readState()
  const history = useHistory()

  return (
    <>
      <HeaderWrapper>
        <span>{state?.name}</span>
        <Avatar
          className="p-overlay-badge"
          icon="pi pi-user"
          size="large"
          tw="rounded-full"
          onClick={(e) => overlay?.current?.toggle(e, e.target)}
        />
      </HeaderWrapper>

      <OverlayPanel ref={overlay}>
        <Button
          className="p-button-danger"
          onClick={() => {
            AuthStateManager.clearState()
            history.push('/login')
          }}
        >
          Logout
        </Button>
      </OverlayPanel>
    </>
  )
}

export default Header
