import { Translated } from 'api/core/Translated'
import Joi from 'joi'

export type CreatorDetailFilter = {
  slug?: string
  fullname?: string
  available?: boolean
  enabled?: boolean
}

export type CreatorDetailRequest = {
  slug: string
  status: CreatorStatus
  detail: CreatorDetail
  photos: CreatorPhotos
  limiting: CreatorLimiting
  pages: CreatorHomePage
  audioPricing: CreatorPricing
  videoPricing: CreatorPricing
}

export const CreatorDetailRequestSchema = Joi.object<CreatorDetailRequest>({
  slug: Joi.string().required(),
  status: Joi.object<CreatorStatus>(),
  detail: Joi.object<CreatorDetail>(),
  photos: Joi.object<CreatorPhotos>(),
  limiting: Joi.object<CreatorLimiting>(),
  pages: Joi.object<CreatorHomePage>(),
  audioPricing: Joi.object<CreatorPricing>(),
  videoPricing: Joi.object<CreatorPricing>(),
})

export type CreatorDetailResponse = {
  id?: string
  slug?: string
  status?: CreatorStatus
  detail?: CreatorDetail
  photos?: CreatorPhotos
  limiting?: CreatorLimiting
  pages?: CreatorHomePage
  audioPricing?: CreatorPricing
  videoPricing?: CreatorPricing
  createdAt?: Date
  updatedAt?: Date
}

export type CreatorStatus = {
  enabled: boolean
  available: boolean
  goingFast: boolean
}

export type CreatorDetail = {
  name: Translated
  surname: Translated
  fullname: Translated
  description: Translated
}

export type CreatorPhotos = {
  homePage: string
  profilePage: string
  consumptionPage: string
  profilePageVideo?: string
}

export type CreatorLimiting = {
  audioLimit: Limiting
  videoLimit: Limiting
}

export type CreatorHomePage = {
  homePage: boolean
  homePageOrder: number
}

export type CreatorPricing = {
  enabled: boolean
  price: number
  commission: number
}

export type Limiting = {
  limit: number
  enabled: boolean
}
