import { client } from 'api/api.root'
import { LoginRequest, LoginResponse } from './LoginPayloads'
import { RefreshRequest, RefreshResponse } from './RefreshPayloads'

export default class AuthApi {
  static login = async (payload: LoginRequest): Promise<LoginResponse> => {
    const response = await client.post<LoginResponse>('/auth/login', payload)

    return response.data
  }

  static logout = async (): Promise<void> => {
    await client.post('/auth/logout')
  }

  static refresh = async (
    payload: RefreshRequest
  ): Promise<RefreshResponse> => {
    const response = await client.post<RefreshResponse>(
      '/auth/refresh',
      payload
    )

    return response.data
  }
}
