import tw from 'twin.macro'

export const HeaderText = tw.div`
font-bold text-2xl
`

export const ToolbarWrapper = tw.div`
flex space-x-2 justify-center
`

export const DetailsActionWrapper = tw.div`
flex space-x-2 justify-center
`
