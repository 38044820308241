import MaintainerUserApi from 'api/users/maintainer/MaintainerUserApi'
import {
  MaintainerUserRequest,
  MaintainerUserRequestSchema,
  MaintainerUserResponse,
} from 'api/users/maintainer/MaintainerUserPayloads'
import { AxiosError } from 'axios'
import useYelaForm from 'hooks/useYelaForm'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import 'twin.macro'
import { BooleanColumnTemplate } from 'views/common/list_view/columns/ColumnTemplates'
import { BooleanFilterTemplate } from 'views/common/list_view/filters/BooleanFilter'
import ListView from 'views/common/list_view/ListView'
import MaintainerUserDetail from './MaintainerUserDetail'

const MaintainerUserList = (): JSX.Element => {
  const toast = useRef<Toast>(null)
  const dataTable = useRef<DataTable>(null)
  const [, setEditItem] = useState<MaintainerUserResponse>()
  const [, setShowDialog] = useState<boolean>(false)
  const form = useYelaForm<MaintainerUserRequest>(MaintainerUserRequestSchema)

  return (
    <>
      <Toast ref={toast} />

      <ListView<MaintainerUserResponse, MaintainerUserRequest>
        dataTable={dataTable}
        viewName="User - Maintainers"
        dataKey="id"
        columns={[
          {
            display: 'Name',
            field: 'name',
            sortKey: 'name',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Email',
            field: 'email',
            sortKey: 'email',
            sortable: true,
            filterable: true,
          },
          {
            display: 'Enabled',
            field: 'enabled',
            sortKey: 'enabled',
            sortable: true,
            filterable: true,
            body: (rowData: MaintainerUserResponse) => {
              return BooleanColumnTemplate(rowData?.enabled as boolean)
            },
            filterBody: BooleanFilterTemplate({
              field: 'enabled',
              dataTable: dataTable,
            }),
          },
          {
            display: 'Permissions',
            field: 'permissions',
            sortKey: 'permissions',
            sortable: true,
            filterable: false,
          },
        ]}
        defaultSort={{ sortBy: 'order', desc: false }}
        onFetch={MaintainerUserApi.findAll}
        form={form}
        dialogContent={(id?: string) => (
          <MaintainerUserDetail id={id} form={form} />
        )}
        onNew={async () => {
          setEditItem(undefined)
          setShowDialog(true)
        }}
        onDetails={async (item: MaintainerUserResponse) => {
          setEditItem(item)
          setShowDialog(true)
        }}
        onUpdate={async (id: string) => {
          await MaintainerUserApi.update(id, form.getValues())
        }}
        onCreate={async () => {
          await MaintainerUserApi.create(form.getValues())
        }}
        onDelete={async (items: MaintainerUserResponse[]) => {
          await MaintainerUserApi.deleteAll(
            items.map((i) => i.id as string)
          ).catch((error: AxiosError) => {
            toast.current?.show({
              severity: 'error',
              summary: `Status: ${error.response?.status}`,
              detail: error.response?.data?.message,
              life: 3000,
            })
          })
        }}
      />
    </>
  )
}

export default MaintainerUserList
