import tw, { styled } from 'twin.macro'

export const LoginWrapper = tw.div`
  flex items-center justify-center w-screen h-screen
`

export const LoginForm = styled.form`
  width: 400px;
  ${tw`flex flex-col items-center justify-center rounded-lg`};
  ${tw`p-20 space-y-8`};
  * {
    ${tw`w-full`}
  }
`
