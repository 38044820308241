import { Translated } from 'api/core/Translated'
import Joi from 'joi'

export type PushNotificationRequest = {
  userId?: string
  title?: Translated
  subtitle?: Translated
  content?: Translated
}

export const PushNotificationRequestSchema =
  Joi.object<PushNotificationRequest>({
    userId: Joi.string().required(),
    title: Joi.object<Translated>().required(),
    subtitle: Joi.object<Translated>().required(),
    content: Joi.object<Translated>().required(),
  })
