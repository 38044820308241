import ApiRoot from 'api/api.root'
import { Anonymous } from 'components/auth/Anonymous'
import { Authorized } from 'components/auth/Authorized'
import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import Login from 'views/auth/Login'
import CreatorDetailList from 'views/creator/CreatorList'
import CreatorUserList from 'views/users/creator/CreatorUserList'
import CurrencyList from 'views/currencies/CurrencyList'
import FanUserList from 'views/users/fan/FanUserList'
import MaintainerUserList from 'views/maintainer/MaintainerUserList'
import OccasionsList from 'views/occasions/OccasionsList'
import OrderList from 'views/orders/OrderList'
import PromotionList from 'views/promotions/PromotionList'
import PushNotification from 'views/push_notification/PushNotification'
import QuickCreate from 'views/quick_create/QuickCreate'

function App(): JSX.Element {
  return (
    <>
      <RecoilRoot>
        <BrowserRouter>
          <ApiRoot>
            <Switch>
              <Anonymous path="/login" component={() => <Login />} />
              <Authorized
                exact
                path="/"
                component={() => <OccasionsList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/occasions"
                component={() => <OccasionsList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/currencies"
                component={() => <CurrencyList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/promotions"
                component={() => <PromotionList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/maintainer"
                component={() => <MaintainerUserList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/fan"
                component={() => <FanUserList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/creator"
                component={() => <CreatorUserList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/celebrity-detail"
                component={() => <CreatorDetailList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/orders"
                component={() => <OrderList />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/pushnotification"
                component={() => <PushNotification />}
                permission="TODO"
              />
              <Authorized
                exact
                path="/quick-create"
                component={() => <QuickCreate />}
                permission="TODO"
              />
              {/* <Redirect to="/login" /> */}
            </Switch>
          </ApiRoot>
        </BrowserRouter>
      </RecoilRoot>
    </>
  )
}

export default App
