import Joi from 'joi'

export type LoginRequest = {
  email: string
  password: string
}

export const LoginRequestSchema = Joi.object<LoginRequest>({
  email: Joi.string().email({ tlds: { allow: false } }),
  password: Joi.string().min(6),
})

export type LoginResponse = {
  id: string
  name: string
  permissions: string[]
  accessToken: string
  accessValidUntil: number
  refreshToken: string
  refreshValidUntil: number
}
