import Joi from 'joi'

export type OrderFilter = {
  id?: string
  email?: string
  creatorName?: string
  remainingHours?: number
  status?: string
  messageType?: string
}

export type OrderRequest = {
  notes: string
  language: string
  type: string
  buyerName: string
  instruction: string
  occasion: OccasionInfo
  recipient: RecipientInfo
}

export const OrderRequestSchema = Joi.object<OrderRequest>({
  notes: Joi.string(),
  language: Joi.string().required(),
  type: Joi.string().required(),
  buyerName: Joi.string().required(),
  instruction: Joi.string().required(),
  occasion: Joi.object<OccasionInfo>(),
  recipient: Joi.object<RecipientInfo>(),
})

export type OrderResponse = {
  id?: string
  email?: string
  creatorId?: string
  creatorName?: string
  remainingHours?: number
  status?: string
  messageType?: string
  createdAt?: Date
}

export type OccasionInfo = {
  slug: string
  name: string
}

export type CreatorInfo = {
  id: string
  slug: string
  name: string
}

export type RecipientInfo = {
  name: string
  email: string
  phone: string
  type: string
}

export type WithNotePayload = {
  notes: string
}

export type ExtendByHoursRequest = {
  hours: number
  notes: string
}

export const OrderStatuses = {
  Pending: 'pending',
  WaitingRequestReview: 'waiting-request-review',
  WaitingCreatorResponse: 'waiting-creator-response',
  WaitingResponseReview: 'waiting-response-review',
  ResponseApproved: 'response-approved',
  UploadedFileProcessing: 'uploaded-file-processing',
  Completed: 'completed',
  Refunded: 'refunded',
}

export type OrderDetailResponse = {
  id?: string
  email?: string
  creator?: CreatorInfo
  message?: Message
  status?: string
  uploads?: Upload[]
  createdAt?: Date
}

export type Message = {
  buyerName?: string
  instruction?: string
  language?: string
  messageType?: string
  occasion?: OccasionInfo
  recipient?: RecipientInfo
  recipientType?: string
}

export type Upload = {
  id: string
  uploadedBy?: string
  isManualUpload: boolean
  promotedToSend: boolean
  uploadedAt: string
}

export type OrderUploadUrlResponse = {
  id: string
  url: string
}

export type OrderWatchUrlResponse = {
  url: string
}
